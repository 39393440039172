import React from 'react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';

export interface PrizePoolData {
  id: number;
  rank: string;
  award: number;
}

interface PrizePoolProps {
  prizePools?: PrizePoolData[];
}

const TournamentDetailTabPrizePool: React.FC<PrizePoolProps> = ({ prizePools }) => {
  const renderPrizePool = (payoutInfo: PrizePoolData) => (
    <StyledPrizePoolRow key={payoutInfo.id} selected={false}>
      <StyledPrizePoolCell color={'white'}>{payoutInfo.rank}</StyledPrizePoolCell>
      <StyledPrizePoolCell color={'white'}>{payoutInfo.award}</StyledPrizePoolCell>
    </StyledPrizePoolRow>
  );

  return (
    <PrizePoolContainer>
      <TableSection>
        <HorizontalLayout margin={'32px'} alignItem={'start'}>
          <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Prize Pool'} textAlign={'start'} />
        </HorizontalLayout>
        <StyledTableHeader>
          <StyledPrizePoolHeader>
            <StyledPrizePoolCell color={'gray400'}>Rank</StyledPrizePoolCell>
            <StyledPrizePoolCell color={'gray400'}>Award</StyledPrizePoolCell>
          </StyledPrizePoolHeader>
        </StyledTableHeader>
        <StyledTableBody>{prizePools?.map(renderPrizePool)}</StyledTableBody>
      </TableSection>
    </PrizePoolContainer>
  );
};

const PrizePoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledPrizePoolHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabPrizePool;
