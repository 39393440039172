import { useGetAssetConfigQuery } from '../store/api/assets';
import { AssetType } from '../store/api/responseTypes';

const useAsset = (assetType: AssetType | string) => {
  const { data: assetConfig } = useGetAssetConfigQuery();

  const asset = assetConfig?.data.assets.find(asset => asset.name === assetType) ?? undefined;

  if (asset) {
    return {
      network: asset,
      tokenInfos: asset.cryptoInfos,
      displayDecimal: asset.displayDecimals ?? 2,
      minSendAmount: asset.minSendAmount ?? 0.1
    };
  } else {
    return {
      network: undefined,
      tokenInfos: undefined,
      displayDecimal: 2,
      minSendAmount: 0.1
    };
  }
};

export default useAsset;
