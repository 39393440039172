import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Buttons, HeaderProfile, HeaderWrap, HoverContainer, IconButtons, MenuItem, Menus, MobileIconButtons, SettingButtons, Settings, StyledLogo } from './styles';
import MobileSettingTemplate from '../settings/MobileSettingTemplate';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { SeatStatus } from '../../../store/api/responseTypes';
import authSlice, { setActiveTab, SettingPages, supportNavigation } from '../../../store/slices/authSlice';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import DeckStyleSelector from '../settings/DeckStyleSelector';
import TableStyleSelector from '../settings/TableStyleSelector';
import useDevice from '../../../hooks/useDevice';
import { useNavigate, useParams } from 'react-router-dom';
import { AddChips, MenuIcon, SettingIcon } from '../../common/icon';
import { useUserProfile } from '../../../hooks/useUserProfile';
import MobileMenuTemplate from '../settings/MobileMenuTemplate';
import styled from '@emotion/styled';
import { TextStyles } from '../../../styles/textStyles';
import BaseButton from '../../common/button/BaseButton';
import { useTableContext } from '../../../hooks/TableProvider';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import TournamentInfoBox from '../tournament/TournamentInfoBox';

type OutType = 'Leave' | 'Stand';

const TableHeader = () => {
  const { tableSettings, mySeatStatus, isAddChipAvailable, reservedSitOut, tableId } = useTableContext();
  const navigate = useNavigate();
  const [isSettingVisible, setSettingVisible] = useState(false);
  const { isMobile } = useDevice();
  const path = window.location.pathname.split('/')[1];
  const { user } = useUserProfile();
  const { shareCode } = useParams<{ shareCode: string }>();
  const userSettings = useUserTableSetting();
  const [isSoundEnable, setSoundEnable] = useState(userSettings.isSoundEnabled);
  const leaveOrStand: OutType = mySeatStatus === undefined || mySeatStatus === SeatStatus.STAND ? 'Leave' : 'Stand';
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const addChips = () => {
    dispatch(showModal(ModalType.BuyInModal));
  };

  const stand = () => {
    dispatch(showModal(ModalType.StandModal));
  };

  const onMenuClick = (menu: SettingPages) => {
    switch (menu) {
      case 'Profile':
      case 'Settings':
      case 'Wallet':
      case 'History':
        window.open(`/settings`, '_blank');
        dispatch(setActiveTab(menu));
        break;
      case 'Cash game':
        window.open(`/cashgame`, '_blank');
        break;
    }
    setMenuVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSound = () => {
    const tableId = tableSettings?.tableId;
    if (tableId) {
      setSoundEnable(!isSoundEnable);
      dispatch(
        authSlice.actions.setTableSetting({
          tableId: tableId,
          setting: { ...userSettings, isSoundEnabled: !isSoundEnable }
        })
      );
    }
  };
  const toggleBlindView = () => {
    dispatch(
      authSlice.actions.setTableSetting({
        tableId: tableId,
        setting: { ...userSettings, isBlindView: !userSettings.isBlindView }
      })
    );
  };
  const goHome = () => {
    navigate('/');
  };

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  return (
    <TableHeaderWrap isInTable={true}>
      {isMobile && isSettingVisible && (
        <MobileSettingTemplate
          isAddChipAvailable={isAddChipAvailable}
          isStandAvailable={leaveOrStand === 'Stand'}
          toggleBlindView={toggleBlindView}
          isBlindView={userSettings.isBlindView}
          hasSound={isSoundEnable}
          toggleSound={toggleSound}
          setSettingVisible={setSettingVisible}
          requestAddChips={addChips}
          requestStand={stand}
        />
      )}
      {isMobile && isMenuVisible && <MobileMenuTemplate isInTable={true} setMenuVisible={setMenuVisible} onMenuClick={onMenuClick} />}
      <SettingButtons>
        {isMobile ? (
          <MobileIconButtons>
            <SettingIcon onClick={() => setSettingVisible(true)} />
            {path !== 'tournament' && (
              <AddChips
                opacity={isAddChipAvailable ? 1 : 0.4}
                onClick={() => {
                  if (isAddChipAvailable) {
                    addChips();
                  }
                }}
              />
            )}
          </MobileIconButtons>
        ) : (
          leaveOrStand === 'Stand' &&
          path !== 'tournament' && (
            <Buttons>
              <BaseButton
                onClick={() => {
                  if (isAddChipAvailable) {
                    addChips();
                  }
                }}
                w={107}
                color={'primary'}
                disabled={!isAddChipAvailable}
                size={'btn36'}
                textColor={isAddChipAvailable ? 'white' : 'gray500'}
                textStyle="TextMd_Semibold"
                text="Add Chips"
              />
              <BaseButton onClick={stand} gap={8} leftIcon="LoginIcon" iconSize={16} w={99} color="grayOpacity" size="btn36" textColor="white" textStyle="TextMd_Semibold" text={leaveOrStand} disabled={reservedSitOut} />
            </Buttons>
          )
        )}
        {!isMobile && (
          <IconButtons>
            {path === 'tournament' && (
              <StyledTournamentInfoWrap>
                <TournamentInfoBox />
              </StyledTournamentInfoWrap>
            )}
            <BaseButton onClick={toggleSound} w={36} leftIcon={isSoundEnable ? 'VolumeIcon' : 'VolumeOff'} iconSize={24} size={'btn36'} />
            <HoverContainer onMouseEnter={() => setSettingVisible(true)} onMouseLeave={() => setSettingVisible(false)}>
              <BaseButton w={36} leftIcon="SettingIcon" iconSize={24} size={'btn36'} />
              <Settings isVisible={isSettingVisible}>
                <DeckStyleSelector tableId={tableSettings?.tableId} />
                <TableStyleSelector tableId={tableSettings?.tableId} />
                <HorizontalLayout margin={'0'}>
                  <Text fontStyle={'TextSmall_Medium'} textColor={'white'} text={'Display cash in BB'} />
                  <ToggleSwitch isActive={userSettings.isBlindView} onClick={toggleBlindView} />
                </HorizontalLayout>
              </Settings>
            </HoverContainer>
          </IconButtons>
        )}
      </SettingButtons>
      {user ? (
        isMobile ? (
          <HeaderProfile onClick={() => setMenuVisible(!isMenuVisible)}>
            <MenuIcon />
          </HeaderProfile>
        ) : (
          <HeaderProfile>
            <HoverContainer onClick={() => setMenuVisible(!isMenuVisible)}>
              <Avatar src={user?.profile} alt="Profile" />
              {isMenuVisible && (
                <Menus isVisible={isMenuVisible} ref={menuRef}>
                  {supportNavigation
                    .filter(item => item !== 'Log out')
                    .map(item => (
                      <MenuItem key={item} onClick={() => onMenuClick(item)}>
                        {item}
                      </MenuItem>
                    ))}
                </Menus>
              )}
            </HoverContainer>
          </HeaderProfile>
        )
      ) : (
        !isMobile && (
          <HeaderProfile>
            <BaseButton color="primary" textColor="white" textStyle="TextMd_Semibold" size="btn44" w={121} onClick={showAuthModal} text="Get Started" />
          </HeaderProfile>
        )
      )}
    </TableHeaderWrap>
  );
};

export default TableHeader;

const StyledTournamentInfoWrap = styled.div`
  width: 164px;
  position: relative;
`;

const TableHeaderWrap = styled(HeaderWrap)`
  ${TextStyles.TextMd}
  background-color: 'transparent';
  box-shadow: none;
  position: fixed;
  top: 0;
  gap: 20px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: start;
  z-index: 101;
  justify-content: space-between;
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 4px;
  }
`;

const StyledAddChipsWrap = styled.div<{ isAddChipsAvailable: boolean }>`
  opacity: ${({ isAddChipsAvailable }) => (isAddChipsAvailable ? 1 : 0.4)};
  align-self: center;
`;
