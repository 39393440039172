import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import TournamentDetailTabInformation from './TournamentDetailTabInformation';
import TournamentDetailTabStructure, { BlindScheduleData, PayoutStructureData } from './TournamentDetailTabStructure';
import TournamentDetailTabPrizePool, { PrizePoolData } from './TournamentDetailTabPrizePool';
import TournamentDetailTabChat from './TournamentDetailTabChat';
import TournamentDetailTabPlayers, { TournamentPlayersData } from './TournamentDetailTabPlayers';
import TournamentDetailTabTables, { TournamentTableData } from './TournamentDetailTabTables';
import TournamentDetailTabInTheMoney, { InTheMoneyData } from './TournamentDetailTabInTheMoney';

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  padding: 16px 0;
  flex-shrink: 0;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  padding: 8px 16px;
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};
  border-bottom: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.white}` : 'none')};
  border-style: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TabContent = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
`;

export type TournamentTab = 'Information' | 'Structure' | 'Prize Pool' | 'Lobby Chat' | 'Players' | 'Tables' | 'In The Money';

const TournamentDetailTab: React.FC<{ initialTab: TournamentTab; tabs: TournamentTab[] }> = ({ initialTab, tabs }) => {
  const [activeTab, setActiveTab] = useState<TournamentTab>(initialTab);

  const handleTabClick = (tab: TournamentTab) => {
    setActiveTab(tab);
  };

  const renderTabContent = (tab: TournamentTab) => {
    switch (tab) {
      case 'Information':
        return <TournamentDetailTabInformation />;
      case 'Structure':
        return <TournamentDetailTabStructure blindStructure={blindStructureSample} payoutStructure={payoutStructureSample} />;
      case 'Prize Pool':
        return <TournamentDetailTabPrizePool prizePools={prizePoolSample} />;
      case 'Lobby Chat':
        return <TournamentDetailTabChat />;
      case 'Players':
        return <TournamentDetailTabPlayers tournamentPlayers={tournamentPlayersSample} />;
      case 'Tables':
        return <TournamentDetailTabTables tournamentTables={tournamentTablesSample} />;
      case 'In The Money':
        return <TournamentDetailTabInTheMoney prizePlayers={prizePlayersSample} />;
      default:
        return <div>{tab} Empty Content</div>;
    }
  };

  return (
    <TournamentDetailBox>
      <Tabs>
        {tabs.map(tab => (
          <Tab key={tab} isActive={activeTab === tab} onClick={() => handleTabClick(tab)}>
            {tab}
          </Tab>
        ))}
      </Tabs>
      <TabContent>{renderTabContent(activeTab)}</TabContent>
    </TournamentDetailBox>
  );
};

const blindStructureSample: BlindScheduleData[] = [
  { id: 1, level: 1, blinds: '100/200', ante: 25, period: '15min' },
  { id: 2, level: 2, blinds: '200/400', ante: 50, period: '15min' },
  { id: 3, level: 3, blinds: '300/600', ante: 75, period: '15min' },
  { id: 4, level: 4, blinds: '400/800', ante: 100, period: '15min' },
  { id: 5, level: 5, blinds: '500/1000', ante: 125, period: '15min' },
  { id: 6, level: 6, blinds: '600/1200', ante: 150, period: '15min' },
  { id: 7, level: 7, blinds: '800/1600', ante: 200, period: '15min' },
  { id: 8, level: 8, blinds: '1000/2000', ante: 250, period: '15min' },
  { id: 9, level: 9, blinds: '1200/2400', ante: 300, period: '15min' },
  { id: 10, level: 10, blinds: '1500/3000', ante: 350, period: '15min' }
];

const payoutStructureSample: PayoutStructureData[] = [
  { id: 1, myRank: 1, totalPlayers: 100, entries: '50', placesPaid: 10 },
  { id: 2, myRank: 2, totalPlayers: 100, entries: '48', placesPaid: 10 },
  { id: 3, myRank: 3, totalPlayers: 100, entries: '46', placesPaid: 10 },
  { id: 4, myRank: 4, totalPlayers: 100, entries: '44', placesPaid: 10 },
  { id: 5, myRank: 5, totalPlayers: 100, entries: '42', placesPaid: 10 },
  { id: 6, myRank: 6, totalPlayers: 100, entries: '40', placesPaid: 10 },
  { id: 7, myRank: 7, totalPlayers: 100, entries: '38', placesPaid: 10 },
  { id: 8, myRank: 8, totalPlayers: 100, entries: '36', placesPaid: 10 },
  { id: 9, myRank: 9, totalPlayers: 100, entries: '34', placesPaid: 10 },
  { id: 10, myRank: 10, totalPlayers: 100, entries: '32', placesPaid: 10 }
];

const prizePoolSample: PrizePoolData[] = [
  { id: 1, rank: '1', award: 1000 },
  { id: 2, rank: '2', award: 800 },
  { id: 3, rank: '3', award: 600 },
  { id: 4, rank: '4', award: 400 },
  { id: 5, rank: '5', award: 200 },
  { id: 6, rank: '6-10', award: 100 }
];

const tournamentPlayersSample: TournamentPlayersData[] = [
  { id: 1, rank: 1, flagUrl: '🇺🇸', username: 'PlayerOne', chips: '150,000 (75BB)' },
  { id: 2, rank: 2, flagUrl: '🇬🇧', username: 'PlayerTwo', chips: '140,000 (70BB)' },
  { id: 3, rank: 3, flagUrl: '🇨🇦', username: 'PlayerThree', chips: '130,000 (65BB)' },
  { id: 4, rank: 4, flagUrl: '🇦🇺', username: 'PlayerFour', chips: '120,000 (60BB)' },
  { id: 5, rank: 5, flagUrl: '🇩🇪', username: 'PlayerFive', chips: '110,000 (55BB)' },
  { id: 6, rank: 6, flagUrl: '🇫🇷', username: 'PlayerSix', chips: '100,000 (50BB)' },
  { id: 7, rank: 7, flagUrl: '🇮🇹', username: 'PlayerSeven', chips: '95,000 (47BB)' },
  { id: 8, rank: 8, flagUrl: '🇯🇵', username: 'PlayerEight', chips: '90,000 (45BB)' },
  { id: 9, rank: 9, flagUrl: '🇧🇷', username: 'PlayerNine', chips: '85,000 (42BB)' },
  { id: 10, rank: 10, flagUrl: '🇪🇸', username: 'PlayerTen', chips: '80,000 (40BB)' },
  { id: 11, rank: 11, flagUrl: '🇷🇺', username: 'PlayerEleven', chips: '75,000 (37BB)' },
  { id: 12, rank: 12, flagUrl: '🇮🇳', username: 'PlayerTwelve', chips: '70,000 (35BB)' },
  { id: 13, rank: 13, flagUrl: '🇲🇽', username: 'PlayerThirteen', chips: '65,000 (32BB)' },
  { id: 14, rank: 14, flagUrl: '🇨🇭', username: 'PlayerFourteen', chips: '60,000 (30BB)' },
  { id: 15, rank: 15, flagUrl: '🇳🇱', username: 'PlayerFifteen', chips: '55,000 (27BB)' },
  { id: 16, rank: 16, flagUrl: '🇧🇪', username: 'PlayerSixteen', chips: '50,000 (25BB)' },
  { id: 17, rank: 17, flagUrl: '🇸🇪', username: 'PlayerSeventeen', chips: '45,000 (22BB)' },
  { id: 18, rank: 18, flagUrl: '🇳🇴', username: 'PlayerEighteen', chips: '40,000 (20BB)' },
  { id: 19, rank: 19, flagUrl: '🇰🇷', username: 'PlayerNineteen', chips: '35,000 (17BB)' },
  { id: 20, rank: 20, flagUrl: '🇨🇳', username: 'PlayerTwenty', chips: '30,000 (15BB)' }
];

const tournamentTablesSample: TournamentTableData[] = [
  { id: 1, tableNumber: 1, players: 9, largestStack: 150000, averageStack: 75000, smallestStack: 20000 },
  { id: 2, tableNumber: 2, players: 8, largestStack: 145000, averageStack: 72000, smallestStack: 22000 },
  { id: 3, tableNumber: 3, players: 9, largestStack: 160000, averageStack: 80000, smallestStack: 30000 },
  { id: 4, tableNumber: 4, players: 7, largestStack: 135000, averageStack: 65000, smallestStack: 25000 },
  { id: 5, tableNumber: 5, players: 9, largestStack: 170000, averageStack: 85000, smallestStack: 18000 },
  { id: 6, tableNumber: 6, players: 8, largestStack: 140000, averageStack: 70000, smallestStack: 20000 },
  { id: 7, tableNumber: 7, players: 9, largestStack: 155000, averageStack: 78000, smallestStack: 24000 },
  { id: 8, tableNumber: 8, players: 6, largestStack: 120000, averageStack: 60000, smallestStack: 30000 },
  { id: 9, tableNumber: 9, players: 8, largestStack: 130000, averageStack: 65000, smallestStack: 25000 },
  { id: 10, tableNumber: 10, players: 9, largestStack: 165000, averageStack: 83000, smallestStack: 22000 },
  { id: 11, tableNumber: 11, players: 7, largestStack: 145000, averageStack: 70000, smallestStack: 27000 },
  { id: 12, tableNumber: 12, players: 9, largestStack: 175000, averageStack: 88000, smallestStack: 20000 },
  { id: 13, tableNumber: 13, players: 6, largestStack: 115000, averageStack: 57000, smallestStack: 30000 },
  { id: 14, tableNumber: 14, players: 8, largestStack: 125000, averageStack: 62000, smallestStack: 28000 },
  { id: 15, tableNumber: 15, players: 9, largestStack: 180000, averageStack: 90000, smallestStack: 19000 },
  { id: 16, tableNumber: 16, players: 8, largestStack: 135000, averageStack: 67000, smallestStack: 25000 },
  { id: 17, tableNumber: 17, players: 7, largestStack: 140000, averageStack: 68000, smallestStack: 26000 },
  { id: 18, tableNumber: 18, players: 9, largestStack: 165000, averageStack: 83000, smallestStack: 21000 },
  { id: 19, tableNumber: 19, players: 8, largestStack: 130000, averageStack: 66000, smallestStack: 22000 },
  { id: 20, tableNumber: 20, players: 9, largestStack: 175000, averageStack: 87000, smallestStack: 20000 }
];

const prizePlayersSample: InTheMoneyData[] = [
  { id: 1, rank: 1, flagUrl: '🇺🇸', username: 'PlayerOne', award: 150000 },
  { id: 2, rank: 2, flagUrl: '🇬🇧', username: 'PlayerTwo', award: 140000 },
  { id: 3, rank: 3, flagUrl: '🇨🇦', username: 'PlayerThree', award: 130000 },
  { id: 4, rank: 4, flagUrl: '🇦🇺', username: 'PlayerFour', award: 120000 },
  { id: 5, rank: 5, flagUrl: '🇩🇪', username: 'PlayerFive', award: 110000 },
  { id: 6, rank: 6, flagUrl: '🇫🇷', username: 'PlayerSix', award: 100000 },
  { id: 7, rank: 7, flagUrl: '🇮🇹', username: 'PlayerSeven', award: 95000 },
  { id: 8, rank: 8, flagUrl: '🇯🇵', username: 'PlayerEight', award: 90000 },
  { id: 9, rank: 9, flagUrl: '🇧🇷', username: 'PlayerNine', award: 85000 },
  { id: 10, rank: 10, flagUrl: '🇪🇸', username: 'PlayerTen', award: 80000 },
  { id: 11, rank: 11, flagUrl: '🇷🇺', username: 'PlayerEleven', award: 75000 },
  { id: 12, rank: 12, flagUrl: '🇮🇳', username: 'PlayerTwelve', award: 70000 },
  { id: 13, rank: 13, flagUrl: '🇲🇽', username: 'PlayerThirteen', award: 65000 },
  { id: 14, rank: 14, flagUrl: '🇨🇭', username: 'PlayerFourteen', award: 60000 },
  { id: 15, rank: 15, flagUrl: '🇳🇱', username: 'PlayerFifteen', award: 55000 },
  { id: 16, rank: 16, flagUrl: '🇧🇪', username: 'PlayerSixteen', award: 50000 },
  { id: 17, rank: 17, flagUrl: '🇸🇪', username: 'PlayerSeventeen', award: 45000 },
  { id: 18, rank: 18, flagUrl: '🇳🇴', username: 'PlayerEighteen', award: 40000 },
  { id: 19, rank: 19, flagUrl: '🇰🇷', username: 'PlayerNineteen', award: 35000 },
  { id: 20, rank: 20, flagUrl: '🇨🇳', username: 'PlayerTwenty', award: 30000 }
];

export default TournamentDetailTab;
