import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';
import { ReactComponent as HelpCircle } from '../../../assets/img/help-circle.svg';
import { autoUpdate, useFloating, useFocus, useHover, useInteractions } from '@floating-ui/react';
import GenericSelect from './GenericSelect';
import HorizontalLayout from '../../layout/HorizontalLayout';

export interface SelectableOption<T> {
  key: string | number;
  displayLabel: string;
  displayImage?: string;
  rawData?: T;
}

interface GenericSelectorWithTitleProps<T> {
  title: string;
  options: SelectableOption<T>[];
  titleNode?: React.ReactNode;
  helpMessage?: React.ReactNode;
  showNeedIcon?: boolean;
  showHelpIcon?: boolean;
  labelFontStyle?: keyof typeof TextStyles;
  labelColor?: keyof typeof Colors;
  onChange: (value: SelectableOption<T>) => void;
  useInput?: boolean;
  selectorPlaceholder?: string;
  inputPlaceholder?: string;
}

const GenericSelectorWithTitle = <T,>({ title, options, helpMessage, showNeedIcon = false, showHelpIcon = false, labelFontStyle = 'TextMd_Medium', labelColor = 'gray300', onChange, titleNode, useInput = false, inputPlaceholder = '' }: GenericSelectorWithTitleProps<T>): React.ReactElement => {
  const memoizedOptions = useMemo(() => options, [options]);
  const [selectedValue, setSelectedValue] = useState<SelectableOption<T> | undefined>(memoizedOptions[0]);
  const [isOpened, setIsOpened] = useState(false);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    open: isOpened,
    onOpenChange: isOpen => {
      if (isOpen !== isOpened) {
        setIsOpened(isOpen);
      }
    }
  });

  const hover = useHover(context);
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  const handleChange = (value: SelectableOption<T>) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Section>
      <HorizontalLayout margin={'0'}>
        <TitleWrap>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <Label labelFontStyle={labelFontStyle} labelColor={labelColor}>
              {title}
            </Label>
            {showNeedIcon && (
              <Label labelFontStyle={labelFontStyle} labelColor={'error500'}>
                *
              </Label>
            )}
            {showHelpIcon && <HelpIcon ref={refs.setReference} {...getReferenceProps()} />}
          </HorizontalLayout>
          {helpMessage && isOpened && (
            <Tooltip ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
              {helpMessage}
            </Tooltip>
          )}
        </TitleWrap>
        {titleNode}
      </HorizontalLayout>
      {useInput ? <Input placeholder={inputPlaceholder} value={selectedValue?.displayLabel || ''} onChange={e => handleChange({ key: e.target.value, displayLabel: e.target.value } as SelectableOption<T>)} /> : <GenericSelect options={memoizedOptions} onChange={handleChange} />}
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.span<{ labelFontStyle: keyof typeof TextStyles; labelColor: keyof typeof Colors }>`
  ${({ theme, labelFontStyle }) => theme.textStyles[labelFontStyle]};
  color: ${({ theme, labelColor }) => theme.colors[labelColor]};
  user-select: none;
`;

const HelpIcon = styled(HelpCircle)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray400};

  &:hover {
    color: ${({ theme }) => theme.colors.gray200};
  }
`;

const Input = styled.input`
  display: flex;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    min-height: 40px;
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const Tooltip = styled.div`
  padding: 12px;
  margin: -8px;
  max-width: 280px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.gray800};
`;

export default GenericSelectorWithTitle;
