import React from 'react';
import styled from '@emotion/styled';
import { TournamentPreviewData } from '../../../pages/TournamentLobbyScreen';
import { Colors } from '../../../styles/Colors';

interface TournamentListTemplateProps {
  tournamentList?: TournamentPreviewData[];
  selectedTournament: number | undefined;
  setSelectedTournament: (tournamentId: number) => void;
}

const TournamentListTemplate: React.FC<TournamentListTemplateProps> = ({ tournamentList, selectedTournament, setSelectedTournament }) => {
  const renderTournamentRow = (tournament: TournamentPreviewData) => (
    <StyledRow key={tournament.tournamentId} selected={tournament.tournamentId === selectedTournament} onClick={() => setSelectedTournament(tournament.tournamentId)}>
      <StyledCell color={'gray300'}> {tournament.starting}</StyledCell>
      <StyledCell color={'warning200'}>{tournament.name}</StyledCell>
      <StyledCell color={'gray300'}>{tournament.buyIn}</StyledCell>
      <StyledCell color={'gray300'}>{tournament.players}</StyledCell>
      <StyledCell color={'gray300'}>{tournament.prize}</StyledCell>
    </StyledRow>
  );

  return (
    <Tournaments>
      <StyledTableHeader>
        <ListRowHeader>
          <StyledCell>Status</StyledCell>
          <StyledCell>Tournament</StyledCell>
          <StyledCell>Buy-in</StyledCell>
          <StyledCell>Players</StyledCell>
          <StyledCell>Prize</StyledCell>
        </ListRowHeader>
      </StyledTableHeader>
      <StyledTableBody>{tournamentList?.map(renderTournamentRow)}</StyledTableBody>
    </Tournaments>
  );
};

const Tournaments = styled.div`
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
  min-height: 618px;
  max-height: 55vh;
  height: 55vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    border-radius: 0;
    max-height: max-content;
    height: max-content;
    min-height: 482px;
    margin-bottom: 64px;
  }
`;

const StyledTableHeader = styled.div`
  display: grid;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray750};
  box-sizing: border-box;
`;

const StyledTableBody = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: min-content;
  box-sizing: border-box;
`;

const ListRowHeader = styled.div`
  display: grid;
  grid-template-columns: 1.6fr 2.5fr 1fr 1fr 1fr;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  color: ${({ theme }) => theme.colors.gray400};
`;

const StyledRow = styled.div<{ selected: boolean }>`
  display: grid;
  grid-template-columns: 1.6fr 2.5fr 1fr 1fr 1fr;
  cursor: pointer;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.table_selected : 'transparent')};
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.table_selected};
  }
`;

interface StyledCellProps {
  color?: keyof typeof Colors; // Add color prop
}

const StyledCell = styled.div<StyledCellProps>`
  padding: 12px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)}; // Use custom color or default
`;

export default TournamentListTemplate;
