import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';
import { ReactComponent as HelpCircle } from '../../../assets/img/help-circle.svg';
import { autoUpdate, useFloating, useFocus, useHover, useInteractions } from '@floating-ui/react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import ToggleSwitch from '../switch/ToggleSwitch';

export interface SelectableRakeOption<T> {
  key: string | number;
  displayLabel: string;
  rawData?: T;
}

interface GenericSelectorWithTitleProps<T> {
  title: string;
  titleFontStyle?: keyof typeof TextStyles;
  titleColor?: keyof typeof Colors;

  options: SelectableRakeOption<T>[];
  onChange: (value: SelectableRakeOption<T>) => void;

  showHelpIcon?: boolean;
  helpMessage?: React.ReactNode;
}

const StakeSelect = <T,>({ title, titleFontStyle = 'TextMd_Medium', titleColor = 'gray300', options, helpMessage, showHelpIcon = false, onChange }: GenericSelectorWithTitleProps<T>): React.ReactElement => {
  const memoizedOptions = useMemo(() => options, [options]);
  const [selectedValue, setSelectedValue] = useState<SelectableRakeOption<T> | undefined>(memoizedOptions[0]);
  const [isRakeEnabled, setRakeEnabled] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    open: isOpened,
    onOpenChange: isOpen => {
      if (isOpen !== isOpened) {
        setIsOpened(isOpen);
      }
    }
  });

  const hover = useHover(context);
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  const handleChange = (value: SelectableRakeOption<T>) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Section>
      <HorizontalLayout margin={'0'}>
        <TitleWrap>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <Label labelFontStyle={titleFontStyle} labelColor={titleColor}>
              {title}
            </Label>
            {showHelpIcon && <HelpIcon ref={refs.setReference} {...getReferenceProps()} />}
          </HorizontalLayout>
          {helpMessage && isOpened && (
            <Tooltip ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
              {helpMessage}
            </Tooltip>
          )}
        </TitleWrap>
        <ToggleSwitch isActive={isRakeEnabled} onClick={() => setRakeEnabled(!isRakeEnabled)} />
      </HorizontalLayout>
      {isRakeEnabled && (
        <HorizontalLayout gap={10} margin={'0'}>
          {options.map(option => (
            <RakeOption key={option.key} selected={option.key === selectedValue?.key} onClick={() => handleChange(option)}>
              {option.displayLabel}%
            </RakeOption>
          ))}
        </HorizontalLayout>
      )}
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.span<{ labelFontStyle: keyof typeof TextStyles; labelColor: keyof typeof Colors }>`
  ${({ theme, labelFontStyle }) => theme.textStyles[labelFontStyle]};
  color: ${({ theme, labelColor }) => theme.colors[labelColor]};
  user-select: none;
`;

const HelpIcon = styled(HelpCircle)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray400};

  &:hover {
    color: ${({ theme }) => theme.colors.gray200};
  }
`;

const Tooltip = styled.div`
  padding: 12px;
  margin: -8px;
  max-width: 280px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.gray800};
`;

const RakeOption = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 4px 0;
  border-radius: 8px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary600 : theme.colors.gray750)};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  user-select: none;
  flex: 1;
  text-align: center;
  min-height: 36px;
  align-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary600};
  }
`;

export default StakeSelect;
