import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';

export interface TournamentTableData {
  id: number;
  tableNumber: number;
  players: number;
  largestStack: number;
  averageStack: number;
  smallestStack: number;
}

interface TournamentTableProps {
  tournamentTables?: TournamentTableData[];
}

const TournamentDetailTabTables: React.FC<TournamentTableProps> = ({ tournamentTables }) => {
  const renderRow = (tournamentTable: TournamentTableData) => (
    <TournamentTablesRow key={tournamentTable.id} selected={false}>
      <TournamentTablesCell color={'white'}>{tournamentTable.tableNumber}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.players}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.largestStack}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.averageStack}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.smallestStack}</TournamentTablesCell>
    </TournamentTablesRow>
  );

  return (
    <TournamentTablesContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentTablesHeader>
            <TournamentTablesCell color={'gray400'}>Table</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Player</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Largest Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Average Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Smallest Stack</TournamentTablesCell>
          </TournamentTablesHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentTables?.map(renderRow)}</StyledTableBody>
      </TableSection>
    </TournamentTablesContainer>
  );
};

const TournamentTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentTablesHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesRow = styled(StyledRow)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabTables;
