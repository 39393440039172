import { useSelector } from 'react-redux';
import { selectTableIdByShareCode } from '../store/slices/streaming';
import { RootState } from '../store/store';

export const useTableId = (id?: number): number | undefined => {
  if (id !== undefined) {
    return id;
  }
  const isInTable = window.location.pathname.includes('table');
  const pathList = window.location.pathname.split('/');
  const idOrShareCode = pathList[pathList.length - 1];
  const isNumber = !isNaN(Number(idOrShareCode));
  if (isInTable) {
    if (isNumber) {
      return Number(idOrShareCode);
    }
    return useSelector((state: RootState) => selectTableIdByShareCode(state, idOrShareCode));
  } else {
    return undefined;
  }
};
