import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';

export interface TournamentPlayersData {
  id: number;
  rank: number;
  flagUrl: string;
  username: string;
  chips: string;
}

interface TournamentPlayersProps {
  tournamentPlayers?: TournamentPlayersData[];
}

const TournamentDetailTabPlayers: React.FC<TournamentPlayersProps> = ({ tournamentPlayers }) => {
  const renderRow = (tournamentPlayer: TournamentPlayersData) => (
    <TournamentPlayersRow key={tournamentPlayer.id} selected={false}>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.rank}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.flagUrl}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.username}</TournamentPlayersCell>
      <TournamentPlayersCell color={'white'}>{tournamentPlayer.chips}</TournamentPlayersCell>
    </TournamentPlayersRow>
  );

  return (
    <TournamentPlayersContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentPlayersHeader>
            <TournamentPlayersCell color={'gray400'}>Rank</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Flag</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Player</TournamentPlayersCell>
            <TournamentPlayersCell color={'gray400'}>Chips(BB)</TournamentPlayersCell>
          </TournamentPlayersHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentPlayers?.map(renderRow)}</StyledTableBody>
      </TableSection>
    </TournamentPlayersContainer>
  );
};

const TournamentPlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentPlayersHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
`;

const TournamentPlayersRow = styled(StyledRow)`
  grid-template-columns: 0.8fr 0.8fr 1.5fr 1fr;
`;

const TournamentPlayersCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabPlayers;
