import React, { useState } from 'react';
import styled from '@emotion/styled';

import { ModalType, showModal } from '../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../hooks/useDevice';
import LobbyBanners from '../components/template/settings/LobbyBanners';
import BaseButton from '../components/common/button/BaseButton';
import Footer from '../components/template/footer/Footer';
import { useAuthContext } from '../hooks/AuthContext';
import TournamentListTemplate from '../components/template/tournament/TournamentListTemplate';
import TournamentPreview from '../components/template/tournament/TournamentPreview';
import TournamentCreateTemplate from '../components/template/tournament/TournamentCreateTemplate';

const TournamentLobbyScreen: React.FC = () => {
  const { isAuthorized } = useAuthContext();
  const { isTablet, isMobile } = useDevice();
  const [selectedTournament, setSelectedTournament] = useState<number | undefined>(undefined);
  const [view, setView] = useState<'Lobby' | 'Create'>('Lobby');
  const selectedTournamentData = selectedTournament ? tournamentPreviewDummyData[selectedTournament] : undefined;

  const dispatch = useDispatch();

  const showAuthModal = () => {
    dispatch(showModal(ModalType.AuthModal));
  };

  const showCreateTableModal = () => {
    setView('Create');
  };

  const onCreateTableClick = () => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  };

  if (view === 'Create') {
    return (
      <Wrap>
        <StyledInnerWrap>
          <TournamentCreateTemplate
            onClose={() => {
              setView('Lobby');
            }}
          />
        </StyledInnerWrap>
      </Wrap>
    );
  }
  return (
    <Wrap>
      <StyledInnerWrap>
        <LobbyBanners />
        <StyledButtons>
          <StyledFilters>
            <BaseButton textStyle="TextSmall_Semibold" bgColor={'gray800'} size={isMobile ? 'btn36' : 'btn40'} textColor="gray400" w={48}>
              All
            </BaseButton>
          </StyledFilters>
          <StyledCreateButton>
            <BaseButton textStyle={'TextSmall_Semibold'} size={isMobile ? 'btn36' : 'btn40'} color="primary" textColor="white" minWidth={isMobile ? 110 : 114} onClick={onCreateTableClick}>
              Create Tourney
            </BaseButton>
          </StyledCreateButton>
        </StyledButtons>
        <StyledContentWrap>
          <Background>
            <Content>
              <TournamentListTemplate tournamentList={Object.values(tournamentPreviewDummyData)} setSelectedTournament={setSelectedTournament} selectedTournament={selectedTournament} />
            </Content>
          </Background>
          {!(isTablet || isMobile) && (
            <StyledPreviewWrap>
              <TournamentPreview tournamentData={selectedTournamentData} />
            </StyledPreviewWrap>
          )}
        </StyledContentWrap>
        <Footer />
      </StyledInnerWrap>
    </Wrap>
  );
};

const StyledFilters = styled.div`
  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;
const StyledCreateButton = styled.div`
  @media (max-width: 768px) {
    margin-right: 16px;
  }
`;

const StyledInnerWrap = styled.div`
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding-top: 116px;
  padding-bottom: 364px;
  @media (max-width: 992px) {
    padding-bottom: 458px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 80px;
    height: 100%;
    padding-bottom: 0;
  }
`;

const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: max-content;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledPreviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 360px;
  height: 100%;
  gap: 12px;
`;

const Wrap = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const Background = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    display: flex;
    padding-bottom: 0;
    height: max-content;
    overflow: inherit;
  }
`;

const Content = styled.div`
  width: 720px;
  height: 100%;
  max-width: 728px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    min-width: 100%;
    max-width: 100%;
    height: max-content;
  }
`;

export interface TournamentPreviewData {
  tournamentId: number;
  name: string;
  starting: string;
  lateRegistration: string;
  chips: string;
  buyIn: string;
  guarantee: string;
  overlay: string;
  payoutDistribution: string;
  blindInterval: string;
  startingStack: string;
  players: number;
  reEntries: string;
  estimatedDuration: string;
  prize: number;
}

export const tournamentPreviewDummyData: Record<number, TournamentPreviewData> = {
  1: {
    tournamentId: 1,
    name: 'Nightly Jackpot Jam $1m',
    starting: 'Late Reg (06:20)',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '90 USDT + 10 USDT',
    guarantee: '1200 USDT',
    overlay: '5 USDT',
    payoutDistribution: 'Top 12%',
    blindInterval: '5 min (Turbo)',
    startingStack: '10,000 (100BB)',
    players: 249,
    reEntries: 'Unlimited',
    estimatedDuration: '1h 30m',
    prize: 2500
  },
  2: {
    tournamentId: 2,
    name: 'Weekly USDT Dash $1m',
    starting: 'In 5 mins',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '50 USDT',
    guarantee: '1000 USDT',
    overlay: '10 USDT',
    payoutDistribution: 'Top 10%',
    blindInterval: '10 min (Standard)',
    startingStack: '10,000 (100BB)',
    players: 800,
    reEntries: '2',
    estimatedDuration: '2h',
    prize: 2500
  },
  3: {
    tournamentId: 3,
    name: 'Daily Dollar Dash',
    starting: 'In 10 mins',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '250 USDT',
    guarantee: '2000 USDT',
    overlay: '20 USDT',
    payoutDistribution: 'Top 15%',
    blindInterval: '6 min (Fast)',
    startingStack: '10,000 (100BB)',
    players: 1000,
    reEntries: '3',
    estimatedDuration: '2h 30m',
    prize: 2500
  },
  4: {
    tournamentId: 4,
    name: "Peter's Special Tournament",
    starting: 'Today, 19:00',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '100 USDT',
    guarantee: '500 USDT',
    overlay: '15 USDT',
    payoutDistribution: 'Top 20%',
    blindInterval: '12 min (Slow)',
    startingStack: '10,000 (100BB)',
    players: 500,
    reEntries: 'Unlimited',
    estimatedDuration: '1h 45m',
    prize: 2500
  },
  5: {
    tournamentId: 5,
    name: 'Nightly Freeroll',
    starting: 'In 1 hour',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: 'Free',
    guarantee: '500 USDT',
    overlay: '0 USDT',
    payoutDistribution: 'Top 5%',
    blindInterval: '5 min (Turbo)',
    startingStack: '10,000 (100BB)',
    players: 1500,
    reEntries: 'None',
    estimatedDuration: '2h',
    prize: 2500
  },
  6: {
    tournamentId: 6,
    name: 'High Roller Tournament',
    starting: 'Tomorrow, 12:00',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '500 USDT + 50 USDT',
    guarantee: '5000 USDT',
    overlay: '50 USDT',
    payoutDistribution: 'Top 8%',
    blindInterval: '15 min (Standard)',
    startingStack: '10,000 (100BB)',
    players: 200,
    reEntries: '1',
    estimatedDuration: '3h',
    prize: 2500
  },
  7: {
    tournamentId: 7,
    name: 'Sunday Special $2m GTD',
    starting: 'Sunday, 14:00',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '200 USDT + 20 USDT',
    guarantee: '2000 USDT',
    overlay: '100 USDT',
    payoutDistribution: 'Top 10%',
    blindInterval: '10 min (Turbo)',
    startingStack: '10,000 (100BB)',
    players: 3000,
    reEntries: 'Unlimited',
    estimatedDuration: '4h',
    prize: 2500
  },
  8: {
    tournamentId: 8,
    name: 'Fast & Furious $500k',
    starting: 'In 15 mins',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '100 USDT',
    guarantee: '500 USDT',
    overlay: '50 USDT',
    payoutDistribution: 'Top 5%',
    blindInterval: '3 min (Hyper Turbo)',
    startingStack: '10,000 (100BB)',
    players: 250,
    reEntries: '2',
    estimatedDuration: '1h',
    prize: 2500
  },
  9: {
    tournamentId: 9,
    name: 'Ultimate Knockout $1m',
    starting: 'Tomorrow, 16:00',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '300 USDT + 30 USDT',
    guarantee: '1000 USDT',
    overlay: '20 USDT',
    payoutDistribution: 'Top 10%',
    blindInterval: '8 min (Turbo)',
    startingStack: '10,000 (100BB)',
    players: 400,
    reEntries: '3',
    estimatedDuration: '2h 15m',
    prize: 2500
  },
  10: {
    tournamentId: 10,
    name: 'Super Bounty Hunters $3m',
    starting: 'In 2 days',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '600 USDT + 60 USDT',
    guarantee: '3000 USDT',
    overlay: '100 USDT',
    payoutDistribution: 'Top 12%',
    blindInterval: '20 min (Standard)',
    startingStack: '10,000 (100BB)',
    players: 1200,
    reEntries: '4',
    estimatedDuration: '5h',
    prize: 2500
  }
};

export default TournamentLobbyScreen;
