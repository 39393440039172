import React from 'react';
import Text from '../components/common/text/Text';
import BaseButton from '../components/common/button/BaseButton';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { ModalType, showModal } from '../store/slices/modalSlice';
import { TextStyles } from '../styles/textStyles';
import useDevice from '../hooks/useDevice';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/template/footer/Footer';
import sampleVideo from '../assets/video/sampleVideo.mp4';
import mobileSampleVideo from '../assets/video/sampleVideo_mobile.mp4';
import { useAuthContext } from '../hooks/AuthContext';

const LandingScreen = () => {
  const { isAuthorized } = useAuthContext();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const showCreateTableModal = () => dispatch(showModal(ModalType.CreateTableModal));

  const goLobby = () => {
    navigate('/cashgame');
  };

  return (
    <Background>
      <StyledInner>
        <StartText fontStyle={'Display2xl_Semibold'} textAlign={'start'} text={'Play poker with friends'} />
        <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextXl'} text={'Set up a Hold’em table to play with friends on any web browser.'} textAlign={'start'} />
        <StyledButtons>
          <StartButton
            w={isMobile ? 140 : 220}
            size={isMobile ? 'btn40' : 'btn60'}
            color="primary"
            block={false}
            onClick={() => {
              isAuthorized ? showCreateTableModal() : showAuthModal();
            }}
            text={isAuthorized ? 'Create Table' : 'Get started'}
            textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'}
          />
          {isMobile && <StartButton w={isMobile ? 140 : 220} size={isMobile ? 'btn40' : 'btn60'} color="gray" block={false} onClick={goLobby} text={'Cash game'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} />}
        </StyledButtons>
        <ContentContainer>
          <Video autoPlay loop muted playsInline key={isMobile ? mobileSampleVideo : sampleVideo}>
            <source src={isMobile ? mobileSampleVideo : sampleVideo} type={'video/mp4'} />
            Your browser does not support the video tag.
          </Video>
        </ContentContainer>
      </StyledInner>
      <Footer />
    </Background>
  );
};

const StyledInner = styled.div`
  max-width: 1360px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 16px;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const Background = styled.div`
  background: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
  padding: 178px 120px 454px 120px;
  gap: 16px;
  box-sizing: border-box;
  min-height: 100vh;
  @media (max-width: 992px) {
    padding: 178px 120px 490px 120px;
  }
  @media (max-width: 768px) {
    padding: 112px 20px;
    box-sizing: border-box;
  }
`;

const StartText = styled(Text)<{
  fontStyle: keyof typeof TextStyles;
}>`
  ${({ fontStyle }) => TextStyles[fontStyle]};
  background: linear-gradient(90deg, #fff 0%, #fff 52.75%, #999 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    text-align: start;
    ${({ fontStyle }) => TextStyles['DisplayMd_Bold']};
  }
`;

const StartButton = styled(BaseButton)`
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 7px;
    align-self: start;
  }
`;

const ContentContainer = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  color: white;
  flex: 1;
  margin-top: 44px;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin-top: 12px;
    flex: inherit;
    margin-bottom: 474px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export default LandingScreen;
