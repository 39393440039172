import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useFloating, autoUpdate, useClick, useInteractions } from '@floating-ui/react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';
import HorizontalLayout from '../../layout/HorizontalLayout';
import 'react-calendar/dist/Calendar.css';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../text/Text';
import { CalendarLeft, CalendarRight } from '../icon';
import useDevice from '../../../hooks/useDevice';

interface DateSelectorProps {
  title: string;
  onChange: (value: Date) => void;
  labelFontStyle?: keyof typeof TextStyles;
  labelColor?: keyof typeof Colors;
}

const DateSelector: React.FC<DateSelectorProps> = ({ title, onChange, labelFontStyle = 'TextMd_Medium', labelColor = 'gray300' }) => {
  const { isMobile } = useDevice();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const selectedTimeRef = useRef<HTMLDivElement | null>(null); // 선택된 시간의 ref
  const [activeStartDate, setActiveStartDate] = useState(new Date()); // 현재 달을 관리하는 상태

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    open: isOpened,
    onOpenChange: setIsOpened
  });

  const click = useClick(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click]);

  // 오늘 날짜로부터 14일 뒤를 maxDate로 설정
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 21);

  // 현재 날짜와 시간을 기준으로 1시간 후의 가장 가까운 시간을 설정
  const getClosestNextHour = () => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 5) * 5); // 현재 시간을 5분 단위로 맞춤
    now.setHours(now.getHours() + 1); // 현재 시간에서 1시간을 더함
    now.setSeconds(0, 0); // 초와 밀리초는 0으로 설정
    return now;
  };

  useEffect(() => {
    // 초기값을 현재 시간에서 1시간 후로 설정
    const initialDate = getClosestNextHour();
    setSelectedDate(initialDate);
    onChange(initialDate); // 부모 컴포넌트에 전달
  }, []);

  // 피커가 열릴 때 선택된 시간 아이템으로 자동 스크롤
  useEffect(() => {
    if (isOpened && selectedTimeRef.current) {
      selectedTimeRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, [isOpened]);

  // 5분 단위의 시간을 생성하는 함수
  const generateTimeSlots = () => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        times.push(formattedTime);
      }
    }
    return times;
  };

  // 현재 날짜인 경우, 1시간 이전의 시간을 필터링
  const filterTimeSlotsForCurrentDate = (timeSlots: string[]) => {
    if (!selectedDate) return timeSlots;

    const now = new Date();
    const selectedIsToday = selectedDate.toDateString() === now.toDateString();

    if (selectedIsToday) {
      // 현재 날짜일 경우, 현재 시간 1시간 이후의 시간만 선택 가능
      const oneHourFromNow = new Date(now.getTime() + 60 * 60 * 1000);
      return timeSlots.filter(time => {
        const [hours, minutes] = time.split(':').map(Number);
        const timeDate = new Date();
        timeDate.setHours(hours);
        timeDate.setMinutes(minutes);
        return timeDate >= oneHourFromNow;
      });
    }

    // 현재 날짜가 아닌 경우 전체 시간 목록을 반환
    return timeSlots;
  };

  // 시간을 선택하면 날짜와 시간을 결합한 후 피커를 닫고, 선택된 값을 전달
  const handleTimeSelect = (time: string) => {
    if (selectedDate) {
      const [hours, minutes] = time.split(':').map(Number);
      const updatedDate = new Date(selectedDate);
      updatedDate.setHours(hours);
      updatedDate.setMinutes(minutes);
      updatedDate.setSeconds(0);
      setSelectedDate(updatedDate);
      onChange(updatedDate); // 선택된 날짜와 시간을 부모 컴포넌트에 전달
      setIsOpened(false); // 피커를 닫음
    }
  };

  const timeSlots = useMemo(() => generateTimeSlots(), []); // 전체 시간 목록
  const filteredTimeSlots = useMemo(() => filterTimeSlotsForCurrentDate(timeSlots), [selectedDate, timeSlots]); // 필터링된 시간 목록

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setActiveStartDate(date);
  };

  const goToThisMonth = () => {
    const nextMonth = new Date(activeStartDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setActiveStartDate(nextMonth);
  };

  const goToLastMonth = () => {
    const lastMonth = new Date(activeStartDate);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    setActiveStartDate(lastMonth);
  };

  const isSelectedTime = (time: string) => {
    if (!selectedDate) return false;
    const [hours, minutes] = time.split(':').map(Number);
    return selectedDate.getHours() === hours && selectedDate.getMinutes() === minutes;
  };

  return (
    <Section>
      <HorizontalLayout margin={'0'}>
        <TitleWrap>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <Label labelFontStyle={labelFontStyle} labelColor={labelColor}>
              {title}
            </Label>
            <Label labelFontStyle={labelFontStyle} labelColor={'error500'}>
              *
            </Label>
          </HorizontalLayout>
        </TitleWrap>
        <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textColor={'gray300'} text={new Date().toLocaleString()} />
      </HorizontalLayout>
      <DateInput ref={refs.setReference} onClick={() => setIsOpened(!isOpened)} {...getReferenceProps()}>
        {selectedDate ? selectedDate.toLocaleString('en-US', { minute: '2-digit', hour: '2-digit', day: 'numeric', month: 'short' }) : 'Select Date & Time'}
      </DateInput>
      {isOpened && (
        <CalendarWrap ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <CalendarSection>
            <LinearLayout width={'auto'} gap={8}>
              <HorizontalLayout margin={'0'} width={'100%'}>
                <MonthButton onClick={goToLastMonth}>
                  <CalendarLeft />
                </MonthButton>
                <Text fontStyle={'TextSmall_Medium'} textColor={'gray900'} text={activeStartDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })} />
                <MonthButton onClick={goToThisMonth}>
                  <CalendarRight />
                </MonthButton>
              </HorizontalLayout>
              <Calendar
                view="month"
                calendarType="gregory"
                activeStartDate={activeStartDate}
                defaultValue={selectedDate}
                value={selectedDate}
                minDate={minDate}
                maxDate={maxDate}
                locale={'en-US'}
                goToRangeStartOnSelect={false}
                selectRange={false}
                prev2Label={null}
                next2Label={null}
                showNeighboringDecade={false}
                showNeighboringMonth={true}
                showNeighboringCentury={false}
                showDoubleView={false}
                showWeekNumbers={false}
                showNavigation={false}
                showFixedNumberOfWeeks={true}
                allowPartialRange={false}
                onClickMonth={undefined}
                onClickYear={undefined}
                onClickWeekNumber={undefined}
                onClickDecade={undefined}
                navigationLabel={({ date }) => date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                onClickDay={handleDateChange}
                formatWeekday={(locale, date) => date.toLocaleDateString('en-US', { weekday: 'short' })}
                formatShortWeekday={(locale, date) => date.toLocaleDateString('en-US', { weekday: 'short' })}
              />
            </LinearLayout>
            <VerticalLine />
            <TimeList>
              {filteredTimeSlots.map(time => (
                <TimeItem
                  key={time}
                  ref={isSelectedTime(time) ? selectedTimeRef : null} // 선택된 시간에 ref 연결
                  isSelected={isSelectedTime(time)}
                  onClick={() => handleTimeSelect(time)}
                >
                  {time}
                </TimeItem>
              ))}
            </TimeList>
          </CalendarSection>
        </CalendarWrap>
      )}
    </Section>
  );
};

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .react-calendar {
    border: none;
    flex: 1;
    gap: 10px;
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: unset;
    text-decoration-line: unset;
    color: ${({ theme }) => theme.colors.gray500};
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__navigation button {
    //border: 1px solid #d0d5dd;
    //border-radius: 8px;
  }

  .react-calendar__tile {
    background: none;
    border-radius: 6px;
    height: 36px;
    width: 36px;
    padding: 0;
    gap: 20px;
    align-self: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray900};
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }

  .react-calendar__tile--active {
    background-color: ${({ theme }) => theme.colors.primary600};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${({ theme }) => theme.colors.primary600};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
  }

  .react-calendar__tile:disabled {
    background-color: unset;
    color: ${({ theme }) => theme.colors.gray400};
    border-radius: 6px;
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: 256px;
      //max-width: 100%;
    }
  }
`;

const Label = styled.span<{ labelFontStyle: keyof typeof TextStyles; labelColor: keyof typeof Colors }>`
  ${({ theme, labelFontStyle }) => theme.textStyles[labelFontStyle]};
  color: ${({ theme, labelColor }) => theme.colors[labelColor]};
  user-select: none;
`;

const DateInput = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const CalendarWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  height: 320px;
  align-self: start;
  align-items: start;
  align-content: start;
  margin: 4px 0 0 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.gray800};
  z-index: 10;
  display: flex;
  flex-direction: row; /* 캘린더와 시간 리스트를 나란히 배치 */

  @media (max-width: 768px) {
    flex-direction: column; /* 캘린더와 시간 리스트를 세로로 배치 */
    height: auto;
    margin: 4px 0 0 20px;
  }
`;

const CalendarSection = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TimeList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 288px;
  overflow-y: auto;
  @media (max-width: 768px) {
    max-height: 134px;
  }
`;

const TimeItem = styled.div<{ isSelected: boolean }>`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  padding: 8px;
  width: 81px;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primary100 : theme.colors.gray100)};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.black : theme.colors.black)};
  border: 1px solid ${({ isSelected, theme }) => (isSelected ? theme.colors.primary600 : 'transparent')};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary100};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  border-radius: 1px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray200};
`;

const MonthButton = styled.div`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  //box-shadow: 0 -2px 2px 0 rgba(48, 48, 48, 0.1);
  align-items: center;
  justify-content: center;
  display: flex;
`;
export default DateSelector;
