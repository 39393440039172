import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { MAX_PASSWORD, MAX_TABLE_TITLE } from '../settings/CreateTableTemplate';
import LinearLayout from '../../layout/LinearLayout';
import { LeftText } from '../../common/text/styles';
import TextInput from '../../common/input/TextInput';
import BaseButton from '../../common/button/BaseButton';
import LottieAnimation from '../../common/loading/LottieAnimation';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useCreateConfig } from '../../../hooks/useCreateConfig';
import { splitEmail } from '../../../utils/StringUtil';
import GenericSelectorWithTitle, { SelectableOption } from '../../common/select/GenericSelectorWithTitle';
import { TableCreateAssetConfig } from '../../../store/api/responseTypes';
import { logDebug } from '../../../utils/ConsoleUtil';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import DateSelector from '../../common/select/DateSelector';
import { ArrowLeft } from '../../common/icon';
import StakeSelect from '../../common/select/StakeSelect';

export type CreateTab = 'Quick' | 'Custom';
const tabs: CreateTab[] = ['Quick', 'Custom'];

const useMemoizedOptions = () => {
  return {
    options: useMemo(() => createSelectableOptionArray(['1', '2', '3', '4', '5']), []),
    startingTime: useMemo(() => createSelectableOptionArray(['Nov 10, 10:00 am', 'Nov 14, 10:00 am', 'Nov 17, 10:00 am', 'Nov 20, 10:00 am', 'Dec 10, 10:00 am']), []),
    buyInOptions: useMemo(() => createSelectableOptionArray(['Freeroll', '1 USDT', '2 USDT', '3 USDT', '4 USDT', '5 USDT']), []),
    blindIntervals: useMemo(() => createSelectableOptionArray(['3 min (Hyper-Turbo)', '5 min (Turbo)', '8 min (Standard)', '12 min (Marathon)']), []),
    stackSizes: useMemo(() => createSelectableOptionArray(['10BB (Micro Stack)', '25BB (Short stack)', '50BB (Regular stack)', '100BB (Deep stack)', '200BB (Super stack)']), []),
    lateRegistrations: useMemo(() => createSelectableOptionArray(['None', 'Blind Level 10', 'Blind Level 11', 'Blind Level 12', 'Blind Level 13', 'Blind Level 14', 'Blind Level 15']), []),
    inTheMoneyOptions: useMemo(() => createSelectableOptionArray(['Top 10%', 'Top 15%', 'Top 20%', 'Top 25%', 'Top 30%']), []),
    reEntries: useMemo(() => createSelectableOptionArray(['None (Freeze out)', '1 time', '2 times', '3 times', '4 times', '5 times']), []),
    gameSpeedOptions: useMemo(() => createSelectableOptionArray(['Fast (approx. 60m)', 'Regular (approx. 90m)', 'Slow (approx. 120m)']), [])
  };
};

const createSelectableOptionArray = (arr: string[]): SelectableOption<string>[] => {
  return arr.map((item, index) => ({
    key: index,
    displayLabel: item.toString(),
    rawData: item.toString()
  }));
};

const createAssetConfigOptionArray = (arr: TableCreateAssetConfig[]): SelectableOption<TableCreateAssetConfig>[] => {
  return arr.map(item => ({
    key: item.assetId,
    displayLabel: item.assetDisplayName,
    displayImage: item.symbolImage,
    rawData: item
  }));
};

interface TournamentCreateBody {
  title: string;
  password: string;
  startingTime: string;
  chips: string;
  buyIn: string;
  guarantee: string;
  stackSize: string;
  blindInterval: string;
  gameSpeed: string;
  customBranding: string;
  lateRegistration: string;
  payoutDistribution: string;
  reEntries: string;
  inTheMoney: string;
}

interface TournamentCreateProps {
  onClose: () => void;
}

const TournamentCreateTemplate: React.FC<TournamentCreateProps> = ({ onClose }) => {
  const { isMobile } = useDevice();
  const [activeTab, setActiveTab] = useState<CreateTab>('Quick');
  const { user } = useUserProfile();
  const { tableCreateConfig, getAssets } = useCreateConfig();
  const [isFreeGame, setIsFreeGame] = useState<boolean>(false);
  const [isFulfilled, setIsFulfilled] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [tournamentDetails, setTournamentDetails] = useState<TournamentCreateBody>({
    title: splitEmail(user?.name) + '`s Tournament',
    password: '',
    startingTime: '',
    chips: '',
    buyIn: '',
    guarantee: '',
    stackSize: '',
    gameSpeed: '',
    customBranding: '',
    blindInterval: '',
    lateRegistration: '',
    payoutDistribution: '',
    reEntries: '',
    inTheMoney: ''
  });
  const { startingTime, buyInOptions, options, blindIntervals, stackSizes, lateRegistrations, inTheMoneyOptions, reEntries, gameSpeedOptions } = useMemoizedOptions();

  const checkIfFulfilled = () => {
    const { title, password, startingTime, chips, buyIn, stackSize, blindInterval, payoutDistribution, lateRegistration, reEntries, inTheMoney, customBranding, gameSpeed, guarantee } = tournamentDetails;
    if (activeTab === 'Quick') {
      if (isFreeGame) {
        if (title && password.length === 4 && startingTime && chips && gameSpeed) {
          setIsFulfilled(true);
        } else {
          setIsFulfilled(false);
        }
      } else {
        if (title && password.length === 4 && startingTime && chips && buyIn && guarantee && gameSpeed) {
          setIsFulfilled(true);
        } else {
          setIsFulfilled(false);
        }
      }
    } else {
      if (isFreeGame) {
        if (title && password.length === 4 && startingTime && chips && stackSize && blindInterval && lateRegistration && inTheMoney && reEntries) {
          setIsFulfilled(true);
        } else {
          setIsFulfilled(false);
        }
      } else {
        if (title && password.length === 4 && startingTime && chips && buyIn && guarantee && stackSize && blindInterval && lateRegistration && payoutDistribution && reEntries) {
          setIsFulfilled(true);
        } else {
          setIsFulfilled(false);
        }
      }
    }
  };

  useEffect(() => {
    logDebug(tournamentDetails);
    checkIfFulfilled();
  }, [tournamentDetails]);

  const getTabTitle = (tab: CreateTab) => {
    switch (tab) {
      case 'Quick':
        return 'Quick start';
      case 'Custom':
        return 'Customize';
      default:
        return 'Create a Tournament';
    }
  };

  const handleTabClick = (tab: CreateTab) => {
    setActiveTab(tab);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (newValue.length > MAX_TABLE_TITLE) {
      newValue = newValue.substring(0, MAX_TABLE_TITLE);
    }
    setTournamentDetails(prev => ({ ...prev, title: newValue }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value.replace(/\D/g, ''); // Only allow digits
    if (newValue.length > MAX_PASSWORD) {
      newValue = newValue.substring(0, MAX_PASSWORD);
    }
    setTournamentDetails(prev => ({ ...prev, password: newValue }));
  };

  const handleChipsChange = (value: SelectableOption<TableCreateAssetConfig>) => {
    setTournamentDetails(prev => ({ ...prev, chips: value.displayLabel }));
    setIsFreeGame(value.rawData?.asset !== 'USDT');
  };

  const handleFieldChange = (field: keyof TournamentCreateBody, value: SelectableOption<any>) => {
    if (value.displayLabel !== tournamentDetails[field]) {
      setTournamentDetails(prev => ({ ...prev, [field]: value.displayLabel }));
    }
  };

  const handleDateChange = (field: keyof TournamentCreateBody, value: Date) => {
    if (value.toString() !== tournamentDetails[field]) {
      setTournamentDetails(prev => ({ ...prev, [field]: value.toLocaleString('en-US', { minute: '2-digit', hour: '2-digit', day: 'numeric', month: 'short' }) }));
    }
  };

  const handleCreateTournament = () => {
    onClose();
  };

  const handlePreviewTournament = () => {
    if (isFulfilled) {
      setIsPreview(true);
    }
  };

  const getErrorMessage = (password: string) => {
    if (/\D/g.test(password)) {
      return 'Enter numbers only (eg. 1234)';
    } else if (!/\D/g.test(password) && password.length !== 4 && password.length !== 0) {
      return 'Password must be 4 digits';
    } else {
      return '';
    }
  };

  const renderPreview = () => {
    const detailsToDisplay = [
      { label: 'Starting Time', key: 'startingTime', showHelpIcon: false, helpMessage: '' },
      { label: 'Chips', key: 'chips', showHelpIcon: false, helpMessage: '' },
      { label: 'Buy-In', key: 'buyIn', showHelpIcon: false, helpMessage: '' },
      { label: 'Guarantee', key: 'guarantee', showHelpIcon: false, helpMessage: '' },
      { label: 'Stack Size', key: 'stackSize', showHelpIcon: false, helpMessage: '' },
      { label: 'Game Speed', key: 'gameSpeed', showHelpIcon: false, helpMessage: '' },
      { label: 'Custom Branding', key: 'customBranding', showHelpIcon: false, helpMessage: '' },
      { label: 'Blind Interval', key: 'blindInterval', showHelpIcon: false, helpMessage: '' },
      { label: 'Late Registration', key: 'lateRegistration', showHelpIcon: false, helpMessage: '' },
      { label: 'Payout Distribution', key: 'payoutDistribution', showHelpIcon: false, helpMessage: '' },
      { label: 'Re-Entries', key: 'reEntries', showHelpIcon: false, helpMessage: '' },
      { label: 'Password', key: 'password', showHelpIcon: false, helpMessage: '' },
      { label: 'In The Money', key: 'inTheMoney', showHelpIcon: false, helpMessage: '' }
    ];

    return (
      <>
        <HorizontalLayout margin={'0'}>
          <HorizontalLayout margin={'0'} justifyContent={'start'} gap={12}>
            <IconDiv>
              <ArrowLeft onClick={() => setIsPreview(false)} color={'white'} />
            </IconDiv>
            <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} text={'Create a Tournament'} textAlign={'start'} />
          </HorizontalLayout>
          <IconDiv>
            <XCloseIcon onClick={() => setIsPreview(false)} />
          </IconDiv>
        </HorizontalLayout>
        <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} text={'Step 2/2 : Check if the parameters are correct'} textAlign={'start'} />
        <PreviewBoundary>
          <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} marginBottom={8} textColor={'white'} text={tournamentDetails.title} textAlign={'start'} />
          {detailsToDisplay.map(({ label, key, showHelpIcon, helpMessage }) => {
            const value = tournamentDetails[key as keyof TournamentCreateBody];
            return value ? (
              <TitleValueHorizontal key={key} label={label} labelColor={'gray400'} value={value} labelFontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} valueFontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} showHelpIcon={showHelpIcon} helpMessage={helpMessage} />
            ) : null;
          })}
        </PreviewBoundary>
        <Text marginBottom={24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} text={'Once the tournament starts, the parameters above cannot be edited.\\nPlease ensure all parameters are correct.'} textAlign={'center'} />
        <BaseButton size={isMobile ? 'btn40' : 'btn60'} text={'Create Tournament'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} color={'primary'} onClick={handleCreateTournament} />
      </>
    );
  };

  const renderTabContent = (tab: CreateTab) => {
    if (!tableCreateConfig) {
      return <LottieAnimation />;
    }
    if (isMobile) {
      return (
        <LinearLayout gap={16}>
          <LinearLayout gap={4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <LeftText fontStyle={'TextXs_Medium'} text={'Tournament Title'} />
              <Text fontStyle={'TextXs_Medium'} text={'*'} textColor={'error500'} />
            </HorizontalLayout>
            <TextInput type="text" onChange={handleTitleChange} value={tournamentDetails.title} />
            {tournamentDetails.title.length < 1 && <ErrorMessage>Please enter a tournament title</ErrorMessage>}
          </LinearLayout>
          <>
            <DateSelector title="Starting Time" onChange={value => handleDateChange('startingTime', value)} labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} />
            {getAssets() !== undefined && <GenericSelectorWithTitle<TableCreateAssetConfig> title="Chips" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={createAssetConfigOptionArray(getAssets()!)} onChange={handleChipsChange} showHelpIcon={false} />}
          </>
          {!isFreeGame && (
            <>
              <GenericSelectorWithTitle
                title="Buy-in"
                labelFontStyle={'TextXs_Medium'}
                labelColor={'gray300'}
                options={buyInOptions}
                onChange={value => handleFieldChange('buyIn', value)}
                helpMessage="Select a number from the dropdown"
                showHelpIcon={true}
                showNeedIcon={true}
                useInput={false}
                titleNode={<Text textColor={'gray300'} fontStyle={'TextMd_Medium'} text={'Freeroll'} />}
              />
              <GenericSelectorWithTitle
                title="Guarantee"
                labelFontStyle={'TextXs_Medium'}
                labelColor={'gray300'}
                options={buyInOptions}
                onChange={value => handleFieldChange('guarantee', value)}
                helpMessage="Select a number from the dropdown"
                showHelpIcon={true}
                showNeedIcon={true}
                titleNode={<Text textColor={'gray400'} fontStyle={'TextMd_Medium'} text={'130,000,000 USDT'} />}
              />
            </>
          )}
          {tab === 'Quick' && (
            <>
              <GenericSelectorWithTitle title="Game Speed" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={gameSpeedOptions} onChange={value => handleFieldChange('gameSpeed', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Custom Branding" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={options} onChange={value => handleFieldChange('customBranding', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} useInput={true} />
            </>
          )}
          {tab === 'Custom' && (
            <>
              <GenericSelectorWithTitle title="Stack Sizes" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={stackSizes} onChange={value => handleFieldChange('stackSize', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Blind Intervals" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={blindIntervals} onChange={value => handleFieldChange('blindInterval', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Late Registration" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={lateRegistrations} onChange={value => handleFieldChange('lateRegistration', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              {isFreeGame && <GenericSelectorWithTitle title="In the Money" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={inTheMoneyOptions} onChange={value => handleFieldChange('inTheMoney', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />}
              {!isFreeGame && (
                <GenericSelectorWithTitle title="Payout Distribution" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={inTheMoneyOptions} onChange={value => handleFieldChange('payoutDistribution', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              )}
              <GenericSelectorWithTitle title="Re-entries" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={reEntries} onChange={value => handleFieldChange('reEntries', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Custom Branding" labelFontStyle={'TextXs_Medium'} labelColor={'gray300'} options={options} onChange={value => handleFieldChange('customBranding', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} useInput={true} />
            </>
          )}
          <LinearLayout gap={4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <LeftText fontStyle={'TextXs_Medium'} text={'Password'} />
            </HorizontalLayout>
            <TextInput onChange={handlePasswordChange} value={tournamentDetails.password} placeholder={'Numbers only (eg. 1234)'} />
            <ErrorMessage>{getErrorMessage(tournamentDetails.password)}</ErrorMessage>
          </LinearLayout>
          {tab === 'Quick' && <Text marginBottom={24} fontStyle={'TextSmall_Medium'} textColor={'gray400'} text={'In Quick Start mode, parameters are set up in a standard format. If you wish to edit specific parameters, click Customize.'} textAlign={'center'} />}
          <BaseButton size={'btn40'} text={'Create Tournament'} textStyle={'TextSmall_Semibold'} color={'primary'} onClick={handlePreviewTournament} disabled={!isFulfilled} />
        </LinearLayout>
      );
    }

    return (
      <LinearLayout gap={16}>
        <LinearLayout gap={4}>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <LeftText fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'Tournament Title'} />
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'*'} textColor={'error500'} />
          </HorizontalLayout>
          <TextInput type="text" onChange={handleTitleChange} value={tournamentDetails.title} />
          {tournamentDetails.title.length < 1 && <ErrorMessage>Please enter a tournament title</ErrorMessage>}
        </LinearLayout>
        <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
          <DateSelector title="Starting Time" onChange={value => handleDateChange('startingTime', value)} />
          {getAssets() !== undefined && <GenericSelectorWithTitle<TableCreateAssetConfig> title="Chips" options={createAssetConfigOptionArray(getAssets()!)} onChange={handleChipsChange} showHelpIcon={false} />}
        </HorizontalLayout>
        {!isFreeGame && (
          <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
            <GenericSelectorWithTitle
              title="Buy-in"
              options={buyInOptions}
              onChange={value => handleFieldChange('buyIn', value)}
              helpMessage="Select a number from the dropdown"
              showHelpIcon={true}
              showNeedIcon={true}
              useInput={false}
              titleNode={<Text textColor={'gray300'} fontStyle={'TextMd_Medium'} text={'Freeroll'} />}
            />
            <GenericSelectorWithTitle
              title="Guarantee"
              options={buyInOptions}
              onChange={value => handleFieldChange('guarantee', value)}
              helpMessage="Select a number from the dropdown"
              showHelpIcon={true}
              showNeedIcon={true}
              titleNode={<Text textColor={'gray400'} fontStyle={'TextMd_Medium'} text={'130,000,000 USDT'} />}
            />
          </HorizontalLayout>
        )}
        {tab === 'Quick' && (
          <>
            <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
              <GenericSelectorWithTitle title="Game Speed" options={gameSpeedOptions} onChange={value => handleFieldChange('gameSpeed', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Custom Branding" options={options} onChange={value => handleFieldChange('customBranding', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} useInput={true} />
            </HorizontalLayout>
          </>
        )}
        {tab === 'Custom' && (
          <>
            <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
              <GenericSelectorWithTitle title="Stack Sizes" options={stackSizes} onChange={value => handleFieldChange('stackSize', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Blind Intervals" options={blindIntervals} onChange={value => handleFieldChange('blindInterval', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
            </HorizontalLayout>
            <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
              <GenericSelectorWithTitle title="Late Registration" options={lateRegistrations} onChange={value => handleFieldChange('lateRegistration', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              {isFreeGame && <GenericSelectorWithTitle title="In the Money" options={inTheMoneyOptions} onChange={value => handleFieldChange('inTheMoney', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />}
              {!isFreeGame && <GenericSelectorWithTitle title="Payout Distribution" options={inTheMoneyOptions} onChange={value => handleFieldChange('payoutDistribution', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />}
            </HorizontalLayout>
            <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
              <GenericSelectorWithTitle title="Re-entries" options={reEntries} onChange={value => handleFieldChange('reEntries', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} />
              <GenericSelectorWithTitle title="Custom Branding" options={options} onChange={value => handleFieldChange('customBranding', value)} helpMessage="Select a number from the dropdown" showHelpIcon={true} useInput={true} />
            </HorizontalLayout>
          </>
        )}
        <LinearLayout gap={4}>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <LeftText fontStyle={'TextMd_Medium'} text={'Password'} />
          </HorizontalLayout>
          <TextInput onChange={handlePasswordChange} value={tournamentDetails.password} placeholder={'Numbers only (eg. 1234)'} />
          <ErrorMessage>{getErrorMessage(tournamentDetails.password)}</ErrorMessage>
        </LinearLayout>
        {!isFreeGame && <StakeSelect title="Charge Fee" options={options} onChange={value => {}} helpMessage="Select a number from the dropdown" showHelpIcon={true} />}
        {tab === 'Quick' && (
          <Text marginTop={16} marginBottom={24} fontStyle={isMobile ? 'TextMd_Semibold' : 'TextMd_Medium'} textColor={'gray400'} text={'In Quick Start mode, parameters are set up in a standard format. If you wish to edit specific parameters, click Customize.'} textAlign={'center'} />
        )}
        <BaseButton size={isMobile ? 'btn40' : 'btn60'} text={'Create Tournament'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} color={'primary'} onClick={handlePreviewTournament} disabled={!isFulfilled} />
      </LinearLayout>
    );
  };

  return (
    <TournamentCreateBox>
      {isPreview ? (
        renderPreview()
      ) : (
        <>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} text={'Create a Tournament'} textAlign={'start'} />
            <XCloseIcon onClick={onClose} />
          </HorizontalLayout>
          <Text marginBottom={16} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} text={'Step 1/2 : Fill out tournament details'} textAlign={'start'} />
          <Tabs>
            {tabs.map(tab => (
              <Tab key={tab} onClick={() => handleTabClick(tab)} aria-selected={tab === activeTab}>
                {getTabTitle(tab)}
              </Tab>
            ))}
          </Tabs>
          <TabContent>{renderTabContent(activeTab)}</TabContent>
        </>
      )}
    </TournamentCreateBox>
  );
};

export const TournamentCreateBox = styled.div`
  min-width: 600px;
  width: 600px;
  max-width: 600px;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 40px 40px;

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    padding: 32px 20px;
    border-radius: unset;
    box-shadow: none;
    box-sizing: border-box;
  }
`;

export const PreviewBoundary = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray700};
  border-radius: 12px;
  padding: 24px;
  margin: 24px 0;
  flex: 1;
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 16px;
    margin: 16px 0;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 1000px;
  width: 100%;
`;

export const Tab = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  background: transparent;
  color: ${({ theme }) => theme.colors.gray400};
  border: none;
  padding: 10px;
  margin: 6px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 1000px;

  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.gray600};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXm_Semibold};
  }
`;

const TabContent = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
  margin-top: 24px;
  padding: 1px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  margin-top: 4px;
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

export default TournamentCreateTemplate;
