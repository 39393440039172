import React from 'react';
import styled from '@emotion/styled';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import HorizontalLayout from '../../layout/HorizontalLayout';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../../common/text/Text';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';

const TournamentStateStatus: React.FC = () => {
  return (
    <TournamentDetailBox>
      <Wrap>
        <HorizontalLayout margin={'0'} gap={24}>
          <LinearLayout gap={4}>
            <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Stack Status'} textAlign={'start'} marginBottom={8} />
            <TitleValueHorizontal label={'Smallest'} value={'14,775 (4.2BB)'} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Largest'} value={'173,168 (49.5BB)'} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Average'} value={'70,909 (20.3BB)'} labelColor={'gray400'} valueColor={'gray300'} />
          </LinearLayout>
          <LinearLayout gap={4}>
            <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Player Status'} textAlign={'start'} marginBottom={8} />
            <TitleValueHorizontal label={'Players Left'} value={'10/78'} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Unique Entries'} value={'63'} labelColor={'gray400'} valueColor={'gray300'} />
            <TitleValueHorizontal label={'Re-entries'} value={'15'} labelColor={'gray400'} valueColor={'gray300'} />
          </LinearLayout>
        </HorizontalLayout>
      </Wrap>
    </TournamentDetailBox>
  );
};

const Wrap = styled.div`
  padding: 24px;
`;

export default TournamentStateStatus;
