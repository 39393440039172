import React from 'react';
import { FacebookIcon, TelegramIcon, TwitterIcon } from 'src/components/common/icon';
import { useTableContext } from '../../../../hooks/TableProvider';

const useWaitingMessage = () => {
  const { shareCode } = useTableContext();
  const shareUrl = window.location.origin + 'cashgame/table/' + shareCode;

  const socialIcons = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    telegram: <TelegramIcon />
  };

  const onSocialClick = (type: string) => {
    switch (type) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${shareUrl}`, '_blank');
        break;
      case 'telegram':
        window.open(`https://telegram.me/share/url?url=${shareUrl}`, '_blank');
        break;
      default:
        break;
    }
  };

  return {
    shareUrl,
    socialIcons,
    onSocialClick
  };
};

export default useWaitingMessage;
