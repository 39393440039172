import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import BaseButton from '../../common/button/BaseButton';
import Text from '../../common/text/Text';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import LinearLayout from '../../layout/LinearLayout';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';

const base = Date.now();
const startTime = base - 1 * 60 * 1000; // 1분 전
const endTime = base + 1 * 60 * 1000; // 1분 후

const TournamentStateTimer: React.FC = () => {
  const now = Date.now();
  const [timeLeft, setTimeLeft] = useState(endTime - now);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalTime = endTime - startTime;
  const radius = 50;
  const circumference = 2 * Math.PI * radius;

  const getStrokeDashoffset = () => {
    const progress = timeLeft / totalTime;
    return progress * circumference;
  };

  useEffect(() => {
    setTimeLeft(endTime - Date.now());
    setTimeout(() => setIsAnimating(true), 0); // 애니메이션 활성화

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime >= endTime) {
        setTimeLeft(0); // 종료
      } else if (currentTime <= startTime) {
        setTimeLeft(totalTime); // 시작 전
      } else {
        setTimeLeft(endTime - currentTime); // 남은 시간 업데이트
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime, startTime, totalTime]);

  // 시간 포맷 함수
  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
  };

  return (
    <TournamentDetailBox>
      <Wrap>
        <PrizePoolSection>
          <LinearLayout gap={4}>
            <Text fontStyle="TextMd_Semibold" textColor="white" text="Prize Pool" textAlign={'start'} />
            <Text fontStyle="TextXl_Semibold" textColor="primary400" text="10,000" textAlign={'start'} />
          </LinearLayout>
          <BaseButton size="btn40" color="primary">
            Register
          </BaseButton>
        </PrizePoolSection>
        <CircularTimer>
          <svg width="120" height="120">
            <circle cx="60" cy="60" r={radius} stroke={Colors.primary600} strokeWidth="8" fill="transparent" />
            <circle
              cx="60"
              cy="60"
              r={radius}
              stroke={Colors.primary200}
              strokeWidth="8"
              fill="transparent"
              strokeDasharray={circumference}
              strokeDashoffset={getStrokeDashoffset()}
              strokeLinecap="round"
              style={{
                transition: isAnimating ? 'stroke-dashoffset 1s linear' : 'none',
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%'
              }}
            />
          </svg>
          <TimeDisplay>{formatTime(timeLeft)}</TimeDisplay>
        </CircularTimer>
        <LinearLayout gap={4} flex={1}>
          <Text fontStyle="TextMd_Semibold" textColor="primary500" text="Registering" textAlign={'start'} />
          <TitleValueHorizontal label="Level 20" value="1,750/3,500" labelColor="gray400" valueColor="gray300" />
          <TitleValueHorizontal label="Next Blind" value="07:43" labelColor="gray400" valueColor="gray300" />
          <TitleValueHorizontal label="Next Break" value="37:06" labelColor="gray400" valueColor="gray300" />
        </LinearLayout>
      </Wrap>
    </TournamentDetailBox>
  );
};

const Wrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  color: white;
`;

const PrizePoolSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  justify-content: space-between;
  height: 100%;
  flex: 0.5;
`;

const CircularTimer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 24px;
`;

const TimeDisplay = styled.div`
  position: absolute;
  font-size: 24px;
  color: white;
`;

export default TournamentStateTimer;
