import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';

export const TableSection = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  //margin-bottom: 16px;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledTableHeader = styled.div`
  position: absolute;
  top: 0;
  display: grid;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray750};
  box-sizing: border-box;
`;

export const StyledTableBody = styled.div`
  display: grid;
  width: 100%;
  padding-top: 40px;
  grid-auto-rows: min-content;
  box-sizing: border-box;
  overflow-y: auto;
  flex: 1;
  max-height: 100%;
`;

export const StyledRowHeader = styled.div<{ textStyles?: keyof typeof TextStyles }>`
  display: grid;
  ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextMd_Medium)}
  color: ${({ theme }) => theme.colors.gray400};
`;

export const StyledRow = styled.div<{ selected: boolean; textStyles?: keyof typeof TextStyles }>`
  display: grid;
  cursor: pointer;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.table_selected : 'transparent')};
  ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextMd_Medium)}
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.table_selected};
  }
`;

interface StyledCellProps {
  color?: keyof typeof Colors; // Add color prop
}

export const StyledCell = styled.div<StyledCellProps>`
  padding: 4px;
  display: flex;
  justify-content: center;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
`;
