import React, { useEffect, useState } from 'react';
import Card from '../../../common/card';
import Seat from '../seat';
import Chip from '../../../common/chip';
import useTableStyles from './useTableStyles';
import { CommunityCardsWrap, StyledChipWrap, CenterArea, CombinationText, PlayingArea, Pot, StyledPotArea, TableImg, StyledTableWrap, TotalPot, TableArea, StyledArea, StyledTable, StyledTableLogo, StyledTableText } from './styles';
import TableTimer from './SeatOutCounter';
import useDevice from '../../../../hooks/useDevice';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import { ModalStatus, ModalType } from '../../../../store/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import useTableActions from '../../../../hooks/useTableActions';
import { useTableContext } from '../../../../hooks/TableProvider';
import { useChats } from '../../../../hooks/useChats';
import { ReactComponent as LogoIcon } from '../../../../assets/img/table-logo.svg';
import WaitingMessage from '../waittingMessage';

const Table = () => {
  const [isTournament, setIsTournament] = useState(true);
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const userTableSetting = useUserTableSetting();
  const { tableSettings, pot, sidePots, totalPot, myCombination, mySeatData, communityCards, handRound, availableSeats, totalPotForDisplay, mySeatId } = useTableContext();
  const { tableFontSize, tableRef, seatPosition, hasWinners, positions, customBranding } = useTableStyles();
  const { canReserve, seatOrBuyIn, selectUser, showVerifyPasswordModal } = useTableActions();
  const { emojis } = useChats();
  const { modalResponse } = useSelector((state: RootState) => state.modalStateSlice);

  useEffect(() => {
    if (modalResponse !== null) {
      if (modalResponse.type === ModalType.PasswordVerifyModal && modalResponse.status === ModalStatus.Approve && availableSeats >= 1) {
        seatOrBuyIn(typeof modalResponse.data === 'number' ? modalResponse.data : undefined);
      }
    }
  }, [modalResponse, dispatch]);

  return (
    <StyledTableWrap tableFontSize={tableFontSize}>
      <StyledTable id={'table'} ref={tableRef}>
        <TableArea>
          <StyledArea>
            {positions?.map((pos, i) => (
              <Seat pos={pos} fontSize={tableFontSize} key={'seat' + i} seatPosition={seatPosition(i)} seatId={i} userTableSetting={userTableSetting} selectUser={selectUser} seatOrBuyIn={seatOrBuyIn} canReserve={canReserve} showVerifyPasswordModal={showVerifyPasswordModal} emojis={emojis} />
            ))}
          </StyledArea>
        </TableArea>
        <StyledTableLogo>
          {!customBranding && <LogoIcon />}
          <StyledTableText>{customBranding || 'BASEPOKER'}</StyledTableText>
        </StyledTableLogo>
        <TableImg isMobile={isMobile} src={require(`src/assets/img/${isMobile ? 'mobile-' : ''}table-${userTableSetting?.tableStyle?.toLowerCase() || 'gray'}.png`)} alt="table" />
      </StyledTable>
      <PlayingArea>
        <TableTimer />
        <CenterArea>
          <TotalPot>
            {totalPot ? (
              <>
                Total Pot
                <Pot>{totalPotForDisplay}</Pot>
              </>
            ) : undefined}
          </TotalPot>
          <CombinationText>{myCombination && handRound !== 'SHOWDOWN' ? myCombination : ''}</CombinationText>
          <CommunityCardsWrap>
            {new Array(5).fill(null).map((_, i) => (
              <Card key={`communityCard-${i}`} cardIndex={i} card={communityCards && communityCards?.[i]} cardType={'community'} />
            ))}
          </CommunityCardsWrap>
          <StyledPotArea>
            {!hasWinners && (
              <StyledChipWrap>
                {(sidePots?.length || 0) > 0 ? (
                  sidePots?.map((sidePot, i) => <Chip key={'sidePot-' + i} type={'pot'} stack={sidePot.pot} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />)
                ) : pot ? (
                  <Chip type={'pot'} stack={pot} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />
                ) : undefined}{' '}
              </StyledChipWrap>
            )}
          </StyledPotArea>
        </CenterArea>
      </PlayingArea>

      {!isTournament && !mySeatData?.autoStandAt && <WaitingMessage />}
      {/*{isTournament && <TableMessage  />}*/}
    </StyledTableWrap>
  );
};

export default Table;
