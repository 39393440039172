import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';

export interface InTheMoneyData {
  id: number;
  rank: number;
  flagUrl: string;
  username: string;
  award: number;
}

interface InTheMoneyProps {
  prizePlayers?: InTheMoneyData[];
}

const TournamentDetailTabInTheMoney: React.FC<InTheMoneyProps> = ({ prizePlayers }) => {
  const renderPrizePool = (prizePlayer: InTheMoneyData) => (
    <InTheMoneyRow key={prizePlayer.id} selected={false}>
      <InTheMoneyCell color={'white'}>{prizePlayer.rank}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{prizePlayer.flagUrl}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{prizePlayer.username}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{prizePlayer.award}</InTheMoneyCell>
    </InTheMoneyRow>
  );

  return (
    <InTheMoneyContainer>
      <TableSection>
        <StyledTableHeader>
          <InTheMoneyHeader>
            <InTheMoneyCell color={'gray400'}>Rank</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Flag</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Player</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Award</InTheMoneyCell>
          </InTheMoneyHeader>
        </StyledTableHeader>
        <StyledTableBody>{prizePlayers?.map(renderPrizePool)}</StyledTableBody>
      </TableSection>
    </InTheMoneyContainer>
  );
};

const InTheMoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InTheMoneyHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr 2fr 1fr;
`;

const InTheMoneyRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr 2fr 1fr;
`;

const InTheMoneyCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabInTheMoney;
