import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';

export interface BlindScheduleData {
  id: number;
  level: number;
  blinds: string;
  ante: number;
  period: string;
}

export interface PayoutStructureData {
  id: number;
  myRank: number;
  totalPlayers: number;
  entries: string;
  placesPaid: number;
}

interface TournamentStructureProps {
  blindStructure?: BlindScheduleData[];
  payoutStructure?: PayoutStructureData[];
}

const TournamentDetailTabStructure: React.FC<TournamentStructureProps> = ({ blindStructure, payoutStructure }) => {
  const renderBlindRow = (blindSchedule: BlindScheduleData) => (
    <StyledBlindScheduleRow key={blindSchedule.id} selected={false}>
      <StyledCell color={'gray300'}> {blindSchedule.level}</StyledCell>
      <StyledCell color={'gray300'}>{blindSchedule.blinds}</StyledCell>
      <StyledCell color={'gray300'}>{blindSchedule.ante}</StyledCell>
      <StyledCell color={'gray300'}>{blindSchedule.period}</StyledCell>
    </StyledBlindScheduleRow>
  );

  const renderPayoutRow = (payoutInfo: PayoutStructureData) => (
    <StyledPayoutRow key={payoutInfo.id} selected={false}>
      <StyledCell color={'gray300'}>{payoutInfo.entries}</StyledCell>
      <StyledCell color={'gray300'}>{payoutInfo.placesPaid}</StyledCell>
    </StyledPayoutRow>
  );

  return (
    <StructureContainer>
      <TableSection>
        <HorizontalLayout margin={'0 32px'} alignItem={'start'}>
          <Text fontStyle={'TextMd_Semibold'} marginBottom={12} marginTop={16} textColor={'white'} text={'Blinds Schedule'} textAlign={'start'} />
        </HorizontalLayout>
        <StyledTableHeader>
          <StyledBlindScheduleHeader>
            <StyledCell color={'gray400'}>Level</StyledCell>
            <StyledCell color={'gray400'}>Blinds</StyledCell>
            <StyledCell color={'gray400'}>Ante</StyledCell>
            <StyledCell color={'gray400'}>Minute</StyledCell>
          </StyledBlindScheduleHeader>
        </StyledTableHeader>
        <StyledTableBody>{blindStructure?.map(renderBlindRow)}</StyledTableBody>
      </TableSection>

      <TableSection>
        <HorizontalLayout margin={'0 32px'} alignItem={'start'}>
          <Text fontStyle={'TextMd_Semibold'} marginBottom={12} textColor={'white'} text={'Payout Structure'} textAlign={'start'} />
        </HorizontalLayout>
        <StyledTableHeader>
          <StyledPayoutHeader>
            <StyledCell color={'gray400'}>Entries</StyledCell>
            <StyledCell color={'gray400'}>Places Paid</StyledCell>
          </StyledPayoutHeader>
        </StyledTableHeader>
        <StyledTableBody>{payoutStructure?.map(renderPayoutRow)}</StyledTableBody>
      </TableSection>
    </StructureContainer>
  );
};

const StructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledBlindScheduleHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 2fr 1fr 1fr;
`;

const StyledBlindScheduleRow = styled(StyledRow)`
  grid-template-columns: 1fr 2fr 1fr 1fr;
`;

const StyledPayoutHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPayoutRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

export default TournamentDetailTabStructure;
