import { useEffect, useRef } from 'react';
import { useAuthContext } from './AuthContext';
import usePageVisibility from './usePageVisibility';
import { bpSocketService, SocketNamespace } from '../services/BpWebSocketService';
import { handleGeneralEvent } from '../components/common/toast/BpToast';
import SoundManager from '../utils/SoundUtil';

const useGeneralSocket = (isInTable: boolean) => {
  const { isAuthorized, channel, user } = useAuthContext();
  const isPageVisible = usePageVisibility();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!isInTable) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        bpSocketService.off(SocketNamespace.GENERAL);
        if (isAuthorized && isPageVisible && channel.name) {
          bpSocketService.connect(() => {
            bpSocketService.on(SocketNamespace.GENERAL, msg => {
              handleGeneralEvent(msg, channel);
            });
            bpSocketService.generalJoin(user?.profile?.name);
          });
        }
      }, 1000);
    }
    return () => {
      if (!isInTable) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        bpSocketService.off(SocketNamespace.GENERAL);
      }
    };
  }, [isAuthorized, isPageVisible, channel.name]);
};

export default useGeneralSocket;
