import React from 'react';
import styled from '@emotion/styled';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import { useNavigate } from 'react-router-dom';
import { TournamentPreviewData } from '../../../pages/TournamentLobbyScreen';

const TournamentDetailTabInformation: React.FC = () => {
  const tournamentData: TournamentPreviewData = {
    tournamentId: 1,
    name: 'Nightly Jackpot Jam $1m',
    starting: 'Late Reg (06:20)',
    lateRegistration: '30m (Blind Level 6)',
    chips: 'Tether - USDT',
    buyIn: '90 USDT + 10 USDT',
    guarantee: '1200 USDT',
    overlay: '5 USDT',
    payoutDistribution: 'Top 12%',
    blindInterval: '5 min (Turbo)',
    startingStack: '10,000 (100BB)',
    players: 249,
    reEntries: 'Unlimited',
    estimatedDuration: '1h 30m',
    prize: 2500
  };

  const getDisplayValue = (value: string | number | undefined): string => {
    return value !== undefined && value !== null ? String(value) : '-';
  };

  const handleLinkClick = () => {
    alert('Link Clicked!');
  };

  const renderHelpMessage = (defaultText: string, boldText?: string, clickableText?: string): React.ReactNode => {
    return (
      <>
        {boldText && <BoldText>{boldText}</BoldText>} <DefaultText>{defaultText}</DefaultText> {clickableText && <ClickableText onClick={handleLinkClick}>{clickableText}</ClickableText>}
      </>
    );
  };

  return (
    <Wrap>
      <PreviewHeader>{tournamentData?.name ?? 'Tournament Info'}</PreviewHeader>
      <PreviewDetails>
        <TitleValueHorizontal label={'Starting Time'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.starting)} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Late Registration'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.lateRegistration)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('is a period during which players can join a tournament even after it has officially started.', 'Late Registration')}
        />
        <TitleValueHorizontal label={'Chips'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.chips)} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Buy-in'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.buyIn)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the entry fee required for participating in a tournament. The buy-in amount contributes to the prize pool of the tournament, which is then distributed among the winners according to the tournament's payout distribution structure.", 'Buy-in')}
        />
        <TitleValueHorizontal
          label={'Guarantee'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.guarantee)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the minimum prize pool amount guaranteed by the tournament organizer, regardless of the number of participants. If the total buy-ins don't meet the guarantee, the organizer typically covers the shortfall.", 'Guarantee (GTD)')}
        />
        <TitleValueHorizontal
          label={'Overlay'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.overlay)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('occurs when the guaranteed prize pool exceeds the total buy-ins collected from players. This shortfall is covered by the tournament organizer, providing extra value to the participants.', 'Overlay')}
        />
        <TitleValueHorizontal
          label={'Payout Distribution'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.payoutDistribution)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the method by which the tournament's prize pool is divided among the top-performing players.", 'Payout Distribution')}
        />
        <TitleValueHorizontal
          label={'Blind Interval'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.blindInterval)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('are the duration between increases in the mandatory bets (blinds) during the tournament. Shorter intervals result in shorter tournaments. Blind structure can be viewed', 'Blind Intervals', 'here.')}
        />
        <TitleValueHorizontal label={'Starting Stack'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.startingStack)} showHelpIcon={true} helpMessage={renderHelpMessage('is the amount of chips each player starts with in the tournament.', 'Starting stack')} />
        <TitleValueHorizontal label={'Re-entries'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.reEntries)} showHelpIcon={true} helpMessage={renderHelpMessage('are the number of times a player can re-enter a tournament after being eliminated.', 'Re-entries')} />
        <TitleValueHorizontal label={'Estimated Duration'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.estimatedDuration)} showHelpIcon={false} />
      </PreviewDetails>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 32px;
`;

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.gray800};
`;

const DefaultText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray800};
`;

const ClickableText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.primary600};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue300};
  }
`;

const PreviewHeader = styled.h2`
  margin-bottom: 16px;
  ${({ theme }) => theme.textStyles.TextLg_Semibold}
`;

const PreviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
`;

export default TournamentDetailTabInformation;
