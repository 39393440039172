import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from '../icon/icons/ChevronDown';
import { ReactComponent as SelectedIcon } from '../../../assets/img/ic_selected.svg';
import useDevice from '../../../hooks/useDevice';
import { SelectableOption } from './GenericSelectorWithTitle';
import HorizontalLayout from '../../layout/HorizontalLayout';

interface GenericSelectProps<T> {
  options: SelectableOption<T>[];
  onChange: (value: SelectableOption<T>) => void;
}

interface CustomDropdownProps<T> {
  options: SelectableOption<T>[];
  defaultValue?: SelectableOption<T>;
  onChange: (value: SelectableOption<T>) => void;
}

const GenericSelect = <T,>({ options, onChange }: GenericSelectProps<T>): React.ReactElement => {
  const [selectedOption, setSelectedOption] = useState<SelectableOption<T> | undefined>(options[0]);

  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption);
    }
  }, [selectedOption]);

  const handleOptionChange = (option: SelectableOption<T>) => {
    setSelectedOption(option);
  };

  return <CustomDropdown options={options} defaultValue={selectedOption} onChange={handleOptionChange} />;
};

const CustomDropdown = <T,>({ options, defaultValue, onChange }: CustomDropdownProps<T>): React.ReactElement => {
  const { isMobile } = useDevice();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectableOption<T> | undefined>(defaultValue);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Update selectedOption if defaultValue changes
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option: SelectableOption<T>) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelected isMobile={isMobile} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <HorizontalLayout margin={'0'} justifyContent={'start'}>
              {selectedOption?.displayImage && <DropdownImage src={selectedOption?.displayImage} alt={selectedOption?.displayLabel} />}
              <DropdownText isMobile={isMobile}>{selectedOption.displayLabel}</DropdownText>
            </HorizontalLayout>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        ) : (
          <>
            <DropdownText isMobile={isMobile}>Select an option</DropdownText>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        )}
      </DropdownSelected>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem key={option.key.toString()} onClick={() => handleSelect(option)}>
              <HorizontalLayout margin={'0'} justifyContent={'start'}>
                {option?.displayImage && <DropdownImage src={option?.displayImage} alt={option?.displayLabel} />}
                <DropdownText isMobile={isMobile}>{option.displayLabel}</DropdownText>
              </HorizontalLayout>
              {selectedOption?.key === option.key && <SelectedIcon />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

// Styled components

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const DropdownSelected = styled.div<{ isMobile: boolean }>`
  display: flex;
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
  }

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 92.4%;
  margin-top: 2px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  max-height: 348px;
  overflow-y: auto;
  z-index: 15;
  padding: 10px 10px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
  @media (max-width: 768px) {
    max-height: 150px;
    gap: 4px;
    padding: 16px 10px;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const DropdownImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const DropdownText = styled.div<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.white};
`;

export default GenericSelect;
