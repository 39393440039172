import React from 'react';
import styled from '@emotion/styled';
import { TournamentPreviewData } from '../../../pages/TournamentLobbyScreen';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import BaseButton from '../../common/button/BaseButton';
import { useNavigate } from 'react-router-dom';

interface TournamentPreviewProps {
  tournamentData?: TournamentPreviewData;
}

const TournamentPreview: React.FC<TournamentPreviewProps> = ({ tournamentData }) => {
  const navigate = useNavigate();

  const goDetail = () => {
    navigate(`/tournament/${tournamentData?.tournamentId}`);
  };

  const goRegister = () => {};

  const getDisplayValue = (value: string | number | undefined): string => {
    return value !== undefined && value !== null ? String(value) : '-';
  };

  const handleLinkClick = () => {
    alert('Link Clicked!');
  };

  const renderHelpMessage = (defaultText: string, boldText?: string, clickableText?: string): React.ReactNode => {
    return (
      <>
        {boldText && <BoldText>{boldText}</BoldText>} <DefaultText>{defaultText}</DefaultText> {clickableText && <ClickableText onClick={handleLinkClick}>{clickableText}</ClickableText>}
      </>
    );
  };

  return (
    <PreviewContainer>
      <PreviewHeader>{tournamentData?.name ?? 'Tournament Info'}</PreviewHeader>
      <PreviewDetails>
        <TitleValueHorizontal label={'Starting Time'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.starting)} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Late Registration'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.lateRegistration)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('is a period during which players can join a tournament even after it has officially started.', 'Late Registration')}
        />
        <TitleValueHorizontal label={'Chips'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.chips)} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Buy-in'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.buyIn)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the entry fee required for participating in a tournament. The buy-in amount contributes to the prize pool of the tournament, which is then distributed among the winners according to the tournament's payout distribution structure.", 'Buy-in')}
        />
        <TitleValueHorizontal
          label={'Guarantee'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.guarantee)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the minimum prize pool amount guaranteed by the tournament organizer, regardless of the number of participants. If the total buy-ins don't meet the guarantee, the organizer typically covers the shortfall.", 'Guarantee (GTD)')}
        />
        <TitleValueHorizontal
          label={'Overlay'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.overlay)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('occurs when the guaranteed prize pool exceeds the total buy-ins collected from players. This shortfall is covered by the tournament organizer, providing extra value to the participants.', 'Overlay')}
        />
        <TitleValueHorizontal
          label={'Payout Distribution'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.payoutDistribution)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the method by which the tournament's prize pool is divided among the top-performing players.", 'Payout Distribution')}
        />
        <TitleValueHorizontal
          label={'Blind Interval'}
          labelColor={'gray400'}
          value={getDisplayValue(tournamentData?.blindInterval)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('are the duration between increases in the mandatory bets (blinds) during the tournament. Shorter intervals result in shorter tournaments. Blind structure can be viewed', 'Blind Intervals', 'here.')}
        />
        <TitleValueHorizontal label={'Starting Stack'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.startingStack)} showHelpIcon={true} helpMessage={renderHelpMessage('is the amount of chips each player starts with in the tournament.', 'Starting stack')} />
        <TitleValueHorizontal label={'Re-entries'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.reEntries)} showHelpIcon={true} helpMessage={renderHelpMessage('are the number of times a player can re-enter a tournament after being eliminated.', 'Re-entries')} />
        <TitleValueHorizontal label={'Estimated Duration'} labelColor={'gray400'} value={getDisplayValue(tournamentData?.estimatedDuration)} showHelpIcon={false} />
      </PreviewDetails>
      <StyledButtonWrap>
        <BaseButton textStyle="TextMd_Semibold" size="btn44" color="gray" textColor="white" onClick={goDetail}>
          Lobby
        </BaseButton>
        <BaseButton textStyle="TextMd_Semibold" size="btn44" color="primary" textColor="white" onClick={goRegister}>
          Register
        </BaseButton>
      </StyledButtonWrap>
    </PreviewContainer>
  );
};

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.gray800};
`;

const DefaultText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray800};
`;

const ClickableText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.primary600};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue300};
  }
`;

const PreviewContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

const PreviewHeader = styled.h2`
  margin-bottom: 16px;
  font-size: 24px;
  ${({ theme }) => theme.textStyles.TextLg_Semibold}
`;

const PreviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
`;

const StyledButtonWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export default TournamentPreview;
