import React from 'react';
import styled from '@emotion/styled';
import TournamentStateStatus from '../components/template/tournament/TournamentStateStatus';
import TournamentStateTimer from '../components/template/tournament/TournamentStateTimer';
import TournamentDetailTab, { TournamentTab } from '../components/template/tournament/TournamentDetailTab';
import Footer from '../components/template/footer/Footer';

const infoTab: TournamentTab[] = ['Information', 'Structure', 'Prize Pool'];
const communityTab: TournamentTab[] = ['Lobby Chat', 'Players', 'Tables', 'In The Money'];

const TournamentDetail: React.FC = () => {
  return (
    <DetailContainer>
      <StyledInnerWrap>
        <EqualHeightWrapper maxHeight={164}>
          <TournamentStateTimer />
          <TournamentStateStatus />
        </EqualHeightWrapper>
        <EqualHeightWrapper maxHeight={650}>
          <TournamentDetailTab initialTab="Information" tabs={infoTab} />
          <TournamentDetailTab initialTab="Lobby Chat" tabs={communityTab} />
        </EqualHeightWrapper>
      </StyledInnerWrap>
      <Footer />
    </DetailContainer>
  );
};

export default TournamentDetail;

const DetailContainer = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;

  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray50};
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const StyledInnerWrap = styled.div`
  padding-top: 116px;
  width: 1080px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 364px;
  @media (max-width: 992px) {
    padding-bottom: 458px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 80px;
    height: 100%;
    padding-bottom: 426px;
  }
`;

const EqualHeightWrapper = styled.div<{ maxHeight: number }>`
  min-width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 8px;
  max-height: ${({ maxHeight }) => maxHeight}px;
`;

export const TournamentDetailBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const TournamentInfoBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
